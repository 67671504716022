import { combineReducers } from 'redux';
import { userReducer } from './user.reducer';
import { customerReducer } from './customer.reducer';
import { modalReducer } from './modal.reducer';
import { invoiceReducer } from './invoice.reducer';

const allReducers = combineReducers({
  userReducer,
  customerReducer,
  modalReducer,
  invoiceReducer,
});

export default allReducers;
