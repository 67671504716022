import { IconButton } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const CustomerInfo = ({ customerDetails }) => {
  const navigate = useNavigate();

  const customerUpdateScreen = () => {
    navigate(`/customers/update/${customerDetails?.customerId}`);
  };

  return (
    <React.Fragment>
      <div className='d-flex justify-content-between align-items-center mb-5'>
        <h1 className='m-0'>{`${customerDetails?.title} ${customerDetails?.firstName} ${customerDetails?.lastName}`}</h1>
        <IconButton onClick={customerUpdateScreen} sx={{ borderRadius: '5px' }}>
          <div className='d-flex align-items-center'>
            <img src='/images/edit-customer-icon.svg' />
            <p
              style={{
                color: '#473AB7',
                fontSize: '20px',
                fontWeight: '400',
                margin: 0,
              }}
            >
              Edit
            </p>
          </div>
        </IconButton>
      </div>
      <div className='row'>
        <div className='col-md-4'>
          <p className='customer-info-heading'>Business Name</p>
          <p className='customer-info-content'>
            {customerDetails?.businessName}
          </p>
        </div>
        <div className='col-md-4'>
          <p className='customer-info-heading'>Email</p>
          <p className='customer-info-content'>{customerDetails?.email}</p>
        </div>
        <div className='col-md-4'>
          <p className='customer-info-heading'>Phone</p>
          <p className='customer-info-content'>{customerDetails?.phone}</p>
        </div>
        <div className='col-md-4'>
          <p className='customer-info-heading'>Address</p>
          <p className='customer-info-content mb-0'>
            {customerDetails?.address?.address}
          </p>
          <p className='customer-info-content mb-0'>
            {customerDetails?.address?.city}
          </p>
          <p className='customer-info-content mb-0'>
            {customerDetails?.address?.region}
            <p className='customer-info-content mb-0'>
              {customerDetails?.address?.country}
            </p>
            <p className='customer-info-content'>
              {customerDetails?.address?.postcode}
            </p>
          </p>
        </div>
        <div className='col-md-4'>
          <p className='customer-info-heading'>Notes</p>
          <p className='customer-info-content'>{customerDetails?.notes}</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomerInfo;
