import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { TableCell, TableRow, TableSortLabel } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import CustomButton from '../@generalComponents/CustomButton';
import unicoAxios from '../../utils/unicoAxios';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Loader from '../@generalComponents/Loader';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CustomerDropdown from '../@generalComponents/CustomerDropdown';
import AllInvoicesRow from './rows/AllInvoicesRow';
import EmptyInvoices from '../@generalComponents/EmptyInvoices';

const AllInvoicesGrid = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [rows, setRows] = useState([]);
  const [totalRecords, setTotalRecords] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentStatus, setCurrentStatus] = useState('ALL');
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);

  const currentUser = useSelector((state) => state.userReducer.currentUser);

  useEffect(() => {
    getInvoices();
  }, [currentStatus, currentPage, startDate, endDate, selectedCustomer]);

  const handleSort = (column) => {
    if (sortColumn === column) {
      // If the same column is clicked again, reverse the sort order
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // If a new column is clicked, set it as the sorting column and use the default 'asc' order
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  const sortedRows = () => {
    if (sortColumn && sortOrder) {
      return [...rows].sort((a, b) => {
        if (sortOrder === 'asc') {
          return a[sortColumn] < b[sortColumn] ? -1 : 1;
        } else {
          return a[sortColumn] > b[sortColumn] ? -1 : 1;
        }
      });
    } else {
      // If no sorting is applied, return the original rows
      return rows;
    }
  };

  const getInvoices = async () => {
    setTotalRecords(null);
    let defaultUrl = `invoices/all/${currentUser?.business?.businessId}/${Number(currentPage) - 1
      }?timeZone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;
    if (selectedCustomer) defaultUrl += `&customerId=${selectedCustomer}`;
    if (currentStatus !== 'ALL') defaultUrl += `&status=${currentStatus}`;
    if (startDate && endDate)
      defaultUrl += `&startDate=${moment(startDate).format(
        'YYYY/MM/DD'
      )}&endDate=${moment(endDate).format('YYYY/MM/DD')}`;
    const { invoices, totalRecords } = await unicoAxios({
      method: 'GET',
      url: defaultUrl,
    });
    setTotalRecords(totalRecords);
    setRows(
      invoices?.map((invoice) => {
        return {
          id: invoice?._id,
          customerName:
            invoice?.customer?.firstName + invoice?.customer?.lastName,
          ...invoice,
        };
      })
    );
  };

  const onTabChange = (event, newVal) => {
    setCurrentStatus(newVal);
    setTotalRecords();
    setRows([]);
  };

  const createNewInvoice = () => {
    navigate('/invoices/create');
  };

  return (
    <div className='mt-5'>
      <Tabs
        value={currentStatus}
        onChange={onTabChange}
        aria-label='basic tabs example'
      >
        <Tab label='Open Invoices' value='1' />
        <Tab label='Paid Invoices' value='2' />
        <Tab label='All Invoices' value='ALL' />
      </Tabs>
      <div className='row d-flex justify-content-between mt-2 mb-2'>
        <div className='col-md-4 d-none d-md-flex align-items-center'>
          <p className='invoice-filter-label' style={{}}>
            Date&nbsp;Range:
          </p>
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              setDateRange(update);
            }}
            isClearable={true}
            placeholderText='DD/MM/YYYY-DD/MM/YYYY'
            dateFormat='dd/MM/yyyy'
            onKeyDown={(e) => {
              e.preventDefault();
            }}
          />
        </div>
        <div className='col-md-4 d-none d-md-flex align-items-center'>
          <p className='invoice-filter-label'>Customer:</p>
          <CustomerDropdown
            ultraThin={true}
            onSelect={(customer) => setSelectedCustomer(customer?.customerId)}
          />
        </div>
        <div className='col-md-3 d-flex align-items-center'>
          <CustomButton
            type='medium-purple'
            label='Create New'
            icon={<img src='/images/add-icon.svg' />}
            onClick={createNewInvoice}
          />
        </div>
      </div>
      <Loader renderChildren={totalRecords != null} type='secondary'>
        <TableContainer>
          <Table aria-label='collapsible table'>
            <TableHead>
              <TableRow>
                {!isMediumScreen && (
                  <React.Fragment>
                    <TableCell onClick={() => handleSort('invoiceNumber')}>
                      <TableSortLabel
                        active={sortColumn === 'invoiceNumber'}
                        direction={
                          sortColumn === 'invoiceNumber' ? sortOrder : 'asc'
                        }
                      >
                        Invoice ID
                      </TableSortLabel>
                    </TableCell>
                    <TableCell onClick={() => handleSort('invoiceDate')}>
                      <TableSortLabel
                        active={sortColumn === 'invoiceDate'}
                        direction={
                          sortColumn === 'invoiceDate' ? sortOrder : 'asc'
                        }
                      >
                        Date
                      </TableSortLabel>
                    </TableCell>
                  </React.Fragment>
                )}
                <TableCell onClick={() => handleSort('customerName')}>
                  <TableSortLabel
                    active={sortColumn === 'customerName'}
                    direction={
                      sortColumn === 'customerName' ? sortOrder : 'asc'
                    }
                  >
                    Customer
                  </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => handleSort('total')}>
                  <TableSortLabel
                    active={sortColumn === 'total'}
                    direction={sortColumn === 'total' ? sortOrder : 'asc'}
                  >
                    Total
                  </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => handleSort('status')}>
                  <TableSortLabel
                    active={sortColumn === 'status'}
                    direction={sortColumn === 'status' ? sortOrder : 'asc'}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ width: '20%' }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedRows()?.map((row) => (
                <AllInvoicesRow
                  key={row.id}
                  row={row}
                  getInvoices={getInvoices}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Loader>
      <div className='d-flex justify-content-center mt-3'>
        <Pagination
          count={Math.ceil(totalRecords / 10)}
          shape='rounded'
          page={currentPage}
          onChange={(e, value) => setCurrentPage(value)}
        />
      </div>
    </div>
  );
};

export default AllInvoicesGrid;
