import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CustomButton from '../components/@generalComponents/CustomButton';
import Input from '../components/@generalComponents/Input';
import { useDispatch, useSelector } from 'react-redux';
import unicoAxios from '../utils/unicoAxios';
import moment from 'moment';
import { invoiceStatuses } from '../exports/commonExports';

const ModalAddPayment = ({
  isOpen,
  modalCloseHandler,
  invoiceId,
  invoiceNumber,
  onComplete,
}) => {
  const dispatch = useDispatch();

  const [paymentDate, setPaymentDate] = useState(new Date());

  const currentUser = useSelector((state) => state.userReducer.currentUser);

  const addPaymentOnInvoice = async () => {
    modalCloseHandler();
    await unicoAxios({
      method: 'PATCH',
      url: `invoices/${currentUser?.business?.businessId}/${invoiceId}`,
      body: {
        isPaid: true,
        paymentDate: moment(paymentDate).format('YYYY/MM/DD'),
        status: invoiceStatuses.PAID,
      },
      loadingMessage: 'Adding payment on invoice...',
      successMessage: 'Payment added successfully!',
    });
    if (onComplete) onComplete();
  };

  return (
    <Dialog open={isOpen} onClose={modalCloseHandler} fullWidth maxWidth='xs'>
      <DialogTitle sx={{ paddingBottom: 0 }}>
        <h1 className='modal-title m-0' style={{ lineHeight: 'normal' }}>
          Add Payment
        </h1>
        <DialogContentText id='alert-dialog-description'>
          <p className='modal-desc mb-0'>to invoice #{invoiceNumber}</p>
        </DialogContentText>
      </DialogTitle>
      <DialogContent>
        <p className='modal-desc mb-0 mt-5 fw-600'>
          <p className='mb-0'>Payment Date</p>
          <Input
            type='date'
            thin
            value={paymentDate}
            onChange={(e) => {
              setPaymentDate(e?.$d);
            }}
          />
        </p>
      </DialogContent>
      <DialogActions>
        <CustomButton
          label='Add Payment'
          type='medium-purple'
          customStyle={{
            fontSize: '15px',
            fontWeight: '600',
          }}
          onClick={addPaymentOnInvoice}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ModalAddPayment;
