import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CustomButton from '../components/@generalComponents/CustomButton';

const ModalConfirmation = ({
  isOpen,
  modalCloseHandler,
  title,
  desc,
  footer,
  onConfirm,
  onCancel,
}) => {
  const handleConfirmClick = () => {
    modalCloseHandler();
    if (onConfirm) onConfirm();
  };

  const handleCancelClick = () => {
    modalCloseHandler();
    if (onCancel) onCancel();
  };

  return (
    <Dialog open={isOpen} onClose={modalCloseHandler} maxWidth='xs'>
      <DialogTitle>
        <h1 className='modal-title m-0' style={{ lineHeight: 'normal' }}>
          {title}
        </h1>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          <p className='modal-desc'>{desc}</p>
        </DialogContentText>
        <p className='modal-desc mb-0 mt-5 fw-600'>{footer}</p>
      </DialogContent>
      <DialogActions>
        <CustomButton
          label='Close'
          type='medium-grey'
          customStyle={{
            fontSize: '15px',
            fontWeight: '600',
          }}
          onClick={handleCancelClick}
        />
        <CustomButton
          label='Delete'
          type='medium-purple'
          customStyle={{
            fontSize: '15px',
            fontWeight: '600',
            height: '38px'
          }}
          onClick={handleConfirmClick}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ModalConfirmation;
