import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCustomers } from '../../redux/actions/customer.actions';
import Input from './Input';
import { useSearchParams } from 'react-router-dom';

const CustomerDropdown = ({ label, value, onSelect, ultraThin }) => {
  const [searchParams] = useSearchParams();

  const customers = useSelector((state) => state.customerReducer.allCustomers);

  useEffect(() => {
    const customerId = searchParams.get('customerId');
    if (!customerId || !customers) return;
    const selectedCustomer = customers?.find(
      (customer) => customer.customerId === customerId
    );
    onSelect(selectedCustomer);
  }, [searchParams, customers])

  const onCustomerChange = (e) => {
    const selectedCustomerId = e.target.value;
    const selectedCustomer = customers?.find(
      (customer) => customer.customerId === selectedCustomerId
    );
    onSelect(selectedCustomer);
  };

  return (
    <Input
      className='customer-dropdown'
      label={label}
      type='select'
      thin={!ultraThin}
      ultraThin={ultraThin}
      value={value}
      name='customer'
      placeholder='Select a customer'
      options={customers?.map((customer) => {
        return {
          label: `${customer?.firstName} ${customer?.lastName} - ${customer?.businessName}`,
          value: customer?.customerId,
        };
      })}
      onChange={onCustomerChange}
    />
  );
};

export default CustomerDropdown;
