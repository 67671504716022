import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { TableCell, TableRow } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { useNavigate } from 'react-router-dom';
import RecentInvoicesRow from './rows/RecentInvoicesRow';

const RecentInvoicesGrid = ({ invoices }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [rows, setRows] = useState([]);

  const currentUser = useSelector((state) => state.userReducer.currentUser);

  useEffect(() => {
    setRows(
      invoices?.map((invoice) => {
        return {
          id: invoice?.invoiceId,
          ...invoice,
        };
      })
    );
  }, [invoices]);

  return (
    <TableContainer>
      <Table aria-label='collapsible table'>
        <TableHead>
          <TableRow>
            {!isMediumScreen && (
              <React.Fragment>
                <TableCell>Date</TableCell>
              </React.Fragment>
            )}
            <TableCell>Customer</TableCell>
            <TableCell>Total</TableCell>
            <TableCell>Status</TableCell>
            <TableCell style={{ width: '20%' }}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row) => (
            <RecentInvoicesRow key={row.id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RecentInvoicesGrid;
