import {
  Menu,
  MenuItem,
  ListItemIcon,
  Avatar,
  IconButton,
  Drawer,
  useMediaQuery,
  useTheme,
  Chip,
} from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MobileSidebar from './MobileSidebar';
import AccountMenu from './AccountMenu';
import getNameInitials from '../../utils/getNameInitials';

const Header = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const container =
    window !== undefined ? () => window.document.body : undefined;

  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const currentUser = useSelector((state) => state.userReducer.currentUser);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDrawerToggle = () => {
    setMobileDrawerOpen((prevState) => !prevState);
  };

  return (
    <div className='header-root'>
      {isLargeScreen ? (
        <div className='d-flex justify-content-between w-100'>
          <IconButton onClick={handleDrawerToggle}>
            <img src='/images/menu-icon.svg' width='24px' height='24px' />
          </IconButton>
          <img
            src='/images/unico-logo-large.svg'
            width='137px'
            height='40px'
          />
          <div
            className='d-flex align-items-center header-user-info'
            onClick={handleClick}
          >
            <Avatar sx={{ width: '35px', height: '35px' }}>
              {getNameInitials(
                currentUser?.user?.firstName + ' ' +
                currentUser?.user?.lastName
              )}
            </Avatar>
          </div>
        </div>
      ) : (
        <div className='d-flex justify-content-between w-100'>
          <div
            className='d-flex align-items-center header-user-info'
            onClick={handleClick}
          >
            <Avatar sx={{ width: '35px', height: '35px' }}>
              {getNameInitials(
                currentUser?.user?.firstName + ' ' +
                currentUser?.user?.lastName
              )}
            </Avatar>
            <p className='ms-3 me-3'>
              {currentUser?.user?.firstName + ' ' + currentUser?.user?.lastName}
            </p>
            <img
              className='me-3'
              src='/images/arrow-down.svg'
              width='15px'
            // height='40px'
            />
            <Chip
              label={
                currentUser?.user?.plan == 0 ? 'Free Plan' :
                  'Business Plan'
              }
              sx={{
                width: '98px',
                backgroundColor: '#D8D4FB', // Change the background color
                color: '#473AB7', // Change the font color
                fontSize: '10px', // Change the font size
                fontWeight: '400',
                borderRadius: '15px', // Change the border radius
              }}
            />
          </div>
          <img
            className='ms-5'
            src='/images/unico-logo-large.svg'
            width='137px'
            height='40px'
          />
        </div>
      )}

      <Drawer
        container={container}
        variant='temporary'
        open={mobileDrawerOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
          disableScrollLock: true,
        }}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: 240,
            margin: '10px 0 10px 10px',
            borderRadius: '10px',
            top: '75px',
            height: 'calc(100% - 95px)',
          },
          '& .MuiModal-backdrop': {
            top: '75px',
          },
          '& .MuiDrawer-elevation': {
            boxSizing: 'border-box',
            width: 240,
            margin: '10px 0 10px 10px',
            borderRadius: '10px',
          },
          top: '75px',
        }}
      >
        <MobileSidebar closeSidebar={() => setMobileDrawerOpen(false)} />
      </Drawer>
      <AccountMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
      />
    </div>
  );
};

export default Header;
