import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/@generalComponents/Loader';
import Input from '../components/@generalComponents/Input';
import { Button, Divider } from '@mui/material';
import CustomButton from '../components/@generalComponents/CustomButton';
import unicoAxios from '../utils/unicoAxios';
import { getCurrentUser } from '../redux/actions/user.actions';
import { useNavigate } from 'react-router-dom';

const UpgradePlan = () => {
  const navigate = useNavigate();

  const currentUser = useSelector((state) => state.userReducer.currentUser);

  const goToAccount = () => {
    navigate(`/account`);
  };

  const checkOutBusinessPlan = async () => {
    await unicoAxios({
      method: 'POST',
      url: 'payments/subscribe',
      body: {
        priceId: process.env.REACT_APP_BUSINESS_PLAN_PRICE_ID,
        successUrl: '/invoices/create?upgradeSuccess=true',
        cancelUrl: '/upgrade-plan',
      },
      loadingMessage: 'Stay with us while we check you out...',
      onSuccess: ({ url }) => (window.location.href = url),
    });
  };

  return (
    <Loader renderChildren={currentUser}>
      <div className='d-flex justify-content-between align-items-center'>
        <h1 className='heading-primary mb-0 mt-0'>Upgrade Plan</h1>
        <Button
          onClick={goToAccount}
          style={{
            background: '#F7F7F7',
            height: '39px',
            color: '#374151',
            fontSize: '12px',
            fontWeight: '600',
            width: '150px',
            textTransform: 'none'
          }}
        >
          My Account
        </Button>
      </div>
      <div className='invoice-card'>
        <h4 className='heading-secondary mb-3'>My Subscription</h4>
        <div className='row d-flex align-items-center'>
          <div className='col-md-4 mb-4 mb-md-0'>
            <div className='upgrade-plan-price-container'>
              <p className='title'>FREE</p>
              <div className='d-flex align-items-center'>
                <h1 className='value mb-0'>£0</h1>
                <p className='freq mt-3'>/month</p>
              </div>
              <ul>
                <li className='price-features'>Unlimited Customers</li>
                <li className='price-features'>Up to 3 Invoices</li>
                <li className='price-features'>
                  Summary Reports
                  <span className='coming-soon'>
                    Coming Soon
                  </span>
                </li>
                <li className='price-features strike'>
                  Estimates
                  <span className='coming-soon'>
                    Coming Soon
                  </span>
                </li>
                <li className='price-features strike'>
                  Due-Date Reminders
                  <span className='coming-soon'>
                    Coming Soon
                  </span>
                </li>
                <li className='price-features strike'>
                  Recurring Invoices
                  <span className='coming-soon'>
                    Coming Soon
                  </span>
                </li>
                <li className='price-features strike'>
                  Schedule Invoices
                  <span className='coming-soon'>
                    Coming Soon
                  </span>
                </li>
              </ul>
              <CustomButton label='ACTIVE' type='medium-outline' />
            </div>
          </div>
          <div className='col-md-4 mb-4 mb-md-0'>
            <div className='upgrade-plan-price-container business'>
              <p className='title'>BUSINESS</p>
              <div className='d-flex align-items-center'>
                <h1 className='value mb-0'>£4</h1>
                <p className='decimal'>.99</p>
                <p className='freq mt-3'>/month</p>
              </div>
              <ul
                style={{
                  margin: '18px 0 36px 0'
                }}
              >
                <li className='price-features'>Unlimited Customers</li>
                <li className='price-features'>Unlimited Invoices</li>
                <li className='price-features'>
                  Advanced Reports
                  <span className='coming-soon'>
                    Coming Soon
                  </span>
                </li>
                <li className='price-features'>
                  Estimates
                  <span className='coming-soon'>
                    Coming Soon
                  </span>
                </li>
                <li className='price-features'>
                  Due-Date Reminders
                  <span className='coming-soon'>
                    Coming Soon
                  </span>
                </li>
                <li className='price-features strike'>
                  Recurring Invoices
                  <span className='coming-soon'>
                    Coming Soon
                  </span>
                </li>
                <li className='price-features strike'>
                  Schedule Invoices
                  <span className='coming-soon'>
                    Coming Soon
                  </span>
                </li>
              </ul>
              <CustomButton
                label='UPGRADE'
                type='medium-purple'
                onClick={checkOutBusinessPlan}
              />
            </div>
          </div>
          <div className='col-md-4 mb-4 mb-md-0'>
            <div className='upgrade-plan-price-container'>
              <p className='title'>PREMIUM</p>
              <div className='d-flex align-items-center'>
                <h1 className='value mb-0'>£9</h1>
                <p className='decimal'>.99</p>
                <p className='freq mt-3'>/month</p>
              </div>
              <ul>
                <li className='price-features'>Unlimited Customers</li>
                <li className='price-features'>Unlimited Invoices</li>
                <li className='price-features'>
                  Advanced Reports
                  <span className='coming-soon'>
                    Coming Soon
                  </span>
                </li>
                <li className='price-features'>
                  Estimates
                  <span className='coming-soon'>
                    Coming Soon
                  </span>
                </li>
                <li className='price-features'>
                  Due-Date Reminders
                  <span className='coming-soon'>
                    Coming Soon
                  </span>
                </li>
                <li className='price-features'>
                  Recurring Invoices
                  <span className='coming-soon'>
                    Coming Soon
                  </span>
                </li>
                <li className='price-features'>
                  Schedule Invoices
                  <span className='coming-soon'>
                    Coming Soon
                  </span>
                </li>
              </ul>
              <CustomButton disabled={true} label='COMING SOON' type='medium-outline' />
            </div>
          </div>
        </div>
      </div>
    </Loader>
  );
};

export default UpgradePlan;
