import { useNavigate } from "react-router-dom";

const EmptyRecentInvoices = () => {
  const navigate = useNavigate();

  const goToCreateInvoice = () => {
    navigate('/invoices/create');
  };

  return (
    <div className='d-flex justify-content-center align-items-center mt-5'>
      <div>
        <img src='/images/empty-graphic.svg' />
        <p
          className='text-center mt-3'
          style={{
            fontSize: '20px',
            fontWeight: 600,
            color: '#081159'
          }}
        >
          No invoices yet.&nbsp;
          <span
            className='text-center'
            style={{
              fontSize: '20px',
              fontWeight: 400,
              color: '#473AB7',
              cursor: 'pointer'
            }}
            onClick={goToCreateInvoice}
          >
            Create my first invoice.
          </span>
        </p>
      </div>
    </div>
  );
};

export default EmptyRecentInvoices;
