import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCustomers } from '../../redux/actions/customer.actions';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Chip, Divider, IconButton, TableCell, TableRow } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import CustomButton from '../@generalComponents/CustomButton';
import CustomChip from '../@generalComponents/CustomChip';
import unicoAxios from '../../utils/unicoAxios';
import {
  modalAddPayment,
  modalConfirmation,
} from '../../redux/actions/modal.actions';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Loader from '../@generalComponents/Loader';
import { invoiceStatuses } from '../../exports/commonExports';

const Row = ({ row, getInvoicesOfCustomer }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = useState(false);

  const currentUser = useSelector((state) => state.userReducer.currentUser);

  const editInvoice = () => {
    navigate(`/invoices/${row?._id}`);
  };

  const confirmInvoiceDeletion = () => {
    dispatch(
      modalConfirmation({
        isOpen: true,
        title: 'Delete Invoice',
        desc: 'This will remove the invoice and all the data/statistics associated with it.',
        footer: 'Are you sure you want to continue?',
        onConfirm: deleteInvoice,
      })
    );
  };

  const deleteInvoice = async () => {
    await unicoAxios({
      method: 'DELETE',
      url: `invoices/${currentUser?.business?.businessId}/${row?._id}`,
      loadingMessage: 'Deleting invoice...',
      successMessage: 'Invoice deleted!',
    });
    getInvoicesOfCustomer();
  };

  const openAddPaymentModal = () => {
    dispatch(
      modalAddPayment({
        isOpen: true,
        invoiceId: row?._id,
        invoiceNumber: row?.invoiceNumber,
        onComplete: getInvoicesOfCustomer,
      })
    );
  };

  const removePaymentOnInvoice = async () => {
    await unicoAxios({
      method: 'PATCH',
      url: `invoices/${currentUser?.business?.businessId}/${row?._id}`,
      body: {
        isPaid: false,
        status: invoiceStatuses.SENT,
      },
      loadingMessage: 'Removing payment on invoice...',
      successMessage: 'Payment removed successfully!',
    });
    getInvoicesOfCustomer();
  };

  const duplicateInvoice = async () => {
    const invoice = row;
    await unicoAxios({
      method: 'POST',
      url: `invoices/create/${currentUser?.business?.businessId}`,
      body: {
        customerId: invoice?.customer?._id,
        invoiceDate: moment(invoice?.invoiceDate).format('YYYY/MM/DD'),
        dueDate: moment(invoice?.dueDate).format('YYYY/MM/DD'),
        items: invoice?.items?.map((item) => {
          return {
            name: item?.name,
            quantity: parseFloat(item?.quantity),
            price: parseFloat(item?.price),
            vatPercentage: parseFloat(item?.vatPercentage),
            itemTotal: item?.itemTotal,
          };
        }),
        isVATRegistered: invoice?.isVATRegistered,
        isVATInclusive: invoice?.isVATInclusive,
        vatAmount: invoice?.vatAmount,
        discountType: invoice?.discountType,
        discountValue: parseFloat(invoice?.discountValue),
        discountAmount: invoice?.discountAmount,
        subtotal: parseFloat(invoice?.subtotal),
        total: parseFloat(invoice?.total),
        notes: invoice?.notes,
      },
      loadingMessage: 'Duplicating invoice...',
      successMessage: 'Invoice duplicated!',
    });
    getInvoicesOfCustomer();
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{
          '& > *': { borderBottom: 'unset' },
          height: !isMediumScreen ? 65 : 48,
        }}
      >
        <TableCell component='th' scope='row'>
          #{row.invoiceNumber?.toString().padStart(3, '0')}
        </TableCell>
        {!isMediumScreen && (
          <React.Fragment>
            <TableCell>
              {moment(row.invoiceDate).format('DD/MM/YYYY')}
            </TableCell>
          </React.Fragment>
        )}
        <TableCell>£{row.total}</TableCell>
        <TableCell>
          <CustomChip
            type={
              row.status == 0
                ? 'warning'
                : row.status == 1
                  ? 'danger'
                  : 'success'
            }
            label={
              row.status == 0 ? 'Draft' : row.status == 1 ? 'SENT' : 'PAID'
            }
          />
        </TableCell>
        <TableCell
          sx={!isMediumScreen ? { paddingRight: '0 !important' } : null}
        >
          <div className='d-flex align-items-center'>
            {!isMediumScreen ? (
              <React.Fragment>
                <IconButton
                  aria-label='expand row'
                  size='medium'
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '4px',
                  }}
                  onClick={editInvoice}
                >
                  <img
                    src='/images/edit-icon-dark.svg'
                    width={20}
                    height={20}
                  />
                  <p className='mb-0'>Edit</p>
                </IconButton>
                <IconButton
                  aria-label='expand row'
                  size='small'
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '4px',
                  }}
                  onClick={duplicateInvoice}
                >
                  <img
                    src='/images/duplicate-icon.svg'
                    width={20}
                    height={20}
                  />
                  <p className='mb-0'>Duplicate</p>
                </IconButton>
                <IconButton
                  aria-label='expand row'
                  size='small'
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '4px',
                  }}
                  onClick={confirmInvoiceDeletion}
                >
                  <img src='/images/bin-icon.svg' width={20} height={20} />
                  <p className='mb-0'>Delete</p>
                </IconButton>
                <img
                  className='hover-on'
                  src={
                    !row?.isPaid
                      ? '/images/invoice-paid.svg'
                      : '/images/invoice-unpaid.svg'
                  }
                  onClick={
                    !row?.isPaid ? openAddPaymentModal : removePaymentOnInvoice
                  }
                  width={50}
                  height={50}
                />
              </React.Fragment>
            ) : (
              <IconButton
                className='ms-2'
                aria-label='expand row'
                size='small'
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  borderRadius: '4px',
                }}
                onClick={() => setOpen(!open)}
              >
                <img src='/images/eye-icon.svg' width={20} height={20} />
              </IconButton>
            )}
          </div>
        </TableCell>
      </TableRow>
      {isMediumScreen && (
        <TableRow>
          <TableCell
            sx={
              open && {
                border: 'none',
              }
            }
            colSpan={6}
          >
            <Collapse in={open} timeout='auto' unmountOnExit>
              <div className='grid-row-collapse-container'>
                <span className='fw-400 me-2'>Date:</span>{' '}
                {moment(row.dueDate).format('MM/DD/YYYY')}
                <br />
                <div className='d-flex justify-content-start mt-3 mb-2'>
                  <div>
                    <IconButton
                      aria-label='expand row'
                      size='medium'
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '4px',
                        height: '100%',
                      }}
                      onClick={editInvoice}
                    >
                      <div className='d-flex'>
                        <img
                          src='/images/edit-icon-dark.svg'
                          width={20}
                          height={20}
                        />
                        <p
                          className='mb-0'
                          style={{
                            fontWeight: 400,
                            fontSize: '14px',
                          }}
                        >
                          Edit
                        </p>
                      </div>
                    </IconButton>
                  </div>
                  <Divider
                    orientation='vertical'
                    flexItem
                    sx={{
                      width: '1.5px',
                      bgcolor: 'rgba(225, 225, 225, 1)',
                    }}
                  />
                  <div>
                    <IconButton
                      aria-label='expand row'
                      size='small'
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '4px',
                        height: '100%',
                      }}
                      onClick={duplicateInvoice}
                    >
                      <div className='d-flex'>
                        <img
                          src='/images/duplicate-icon.svg'
                          width={20}
                          height={20}
                        />
                        <p
                          className='mb-0'
                          style={{
                            fontWeight: 400,
                            fontSize: '14px',
                          }}
                        >
                          Duplicate
                        </p>
                      </div>
                    </IconButton>
                  </div>
                  <Divider
                    orientation='vertical'
                    flexItem
                    sx={{
                      width: '1.5px',
                      bgcolor: 'rgba(225, 225, 225, 1)',
                    }}
                  />
                  <div>
                    <IconButton
                      aria-label='expand row'
                      size='small'
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '4px',
                        height: '100%',
                      }}
                      onClick={confirmInvoiceDeletion}
                    >
                      <div className='d-flex'>
                        <img
                          src='/images/bin-icon.svg'
                          width={20}
                          height={20}
                        />
                        <p
                          className='mb-0'
                          style={{
                            fontWeight: 400,
                            fontSize: '14px',
                          }}
                        >
                          Delete
                        </p>
                      </div>
                    </IconButton>
                  </div>
                </div>
                {row?.isPaid ? (
                  <CustomButton
                    type='medium-purple'
                    label='Remove Payment'
                    onClick={removePaymentOnInvoice}
                  />
                ) : (
                  <CustomButton
                    type='medium-purple'
                    label='Add Payment'
                    onClick={openAddPaymentModal}
                  />
                )}
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};

const CustomerInvoicesGrid = ({ customerId }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [rows, setRows] = useState([]);
  const [totalRecords, setTotalRecords] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentStatus, setCurrentStatus] = useState('ALL');

  const currentUser = useSelector((state) => state.userReducer.currentUser);

  useEffect(() => {
    getInvoicesOfCustomer();
  }, [currentStatus]);

  const getInvoicesOfCustomer = async () => {
    let defaultUrl = `invoices/all/${currentUser?.business?.businessId}/${Number(currentPage) - 1
      }?customerId=${customerId}`;
    if (currentStatus !== 'ALL') defaultUrl += `&status=${currentStatus}`;
    const { invoices, totalRecords } = await unicoAxios({
      method: 'GET',
      url: defaultUrl,
    });
    setTotalRecords(totalRecords);
    setRows(
      invoices?.map((invoice) => {
        return {
          id: invoice?._id,
          ...invoice,
        };
      })
    );
  };

  const onTabChange = (event, newVal) => {
    setCurrentStatus(newVal);
    setTotalRecords();
    setRows([]);
  };

  return (
    <div className='mt-5'>
      <div className='row d-flex justify-content-between'>
        {isMediumScreen && (
          <div className='col-md-4'>
            <CustomButton
              type='medium-purple'
              label='Create New'
              icon={<img src='/images/add-icon.svg' />}
            />
          </div>
        )}
        <div className='col-md-8'>
          <Tabs
            value={currentStatus}
            onChange={onTabChange}
            aria-label='basic tabs example'
          >
            <Tab label='Open Invoices' value='1' />
            <Tab label='Paid Invoices' value='2' />
            <Tab label='All Invoices' value='ALL' />
          </Tabs>
        </div>
        {!isMediumScreen && (
          <div className='col-md-4'>
            <CustomButton
              type='medium-purple'
              label='Create New'
              icon={<img src='/images/add-icon.svg' />}
              onClick={() => navigate(`/invoices/create?customerId=${customerId}`)}
            />
          </div>
        )}
      </div>
      <Loader renderChildren={totalRecords != null} type='secondary'>
        <TableContainer>
          <Table aria-label='collapsible table'>
            <TableHead>
              <TableRow>
                <TableCell>Invoice ID</TableCell>
                {!isMediumScreen && <TableCell>Date</TableCell>}
                <TableCell>Total</TableCell>
                <TableCell>Status</TableCell>
                <TableCell style={{ width: '20%' }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row) => (
                <Row
                  key={row.id}
                  row={row}
                  getInvoicesOfCustomer={getInvoicesOfCustomer}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Loader>
      <div className='d-flex justify-content-center mt-3'>
        <Pagination
          count={Math.ceil(totalRecords / 10)}
          shape='rounded'
          page={currentPage}
          onChange={(e, value) => setCurrentPage(value)}
        />
      </div>
    </div>
  );
};

export default CustomerInvoicesGrid;
