import React, { useEffect } from 'react';
import CustomButton from '../../components/@generalComponents/CustomButton';
import { useNavigate } from 'react-router-dom';
import AllCustomersGrid from '../../components/grids/AllCustomersGrid';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/@generalComponents/Loader';
import EmptyCustomers from '../../components/@generalComponents/EmptyCustomers';

const AllCustomers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const customers = useSelector((state) => state.customerReducer.allCustomers);

  const goToCreateCustomer = () => {
    navigate('/customers/create');
  };

  return (
    <Loader renderChildren={customers}>
      <div className='d-flex align-items-center'>
        <img src='/images/unico-logo-small.svg' width={47} height={46} />
        <h1 className='heading-primary mb-0 mt-0 ms-3'>View Customers</h1>
      </div>
      <p className='subtitle-primary'>
        Here is a list of your customers. Click ‘View & Edit’ to go to their
        profile, to edit details and view past invoices
      </p>
      {customers?.length === 0 ? (
        <EmptyCustomers />
      ) : (
        <React.Fragment>
          <div className='row d-flex justify-content-end'>
            <div className='col-md-2 mb-3'>
              <CustomButton
                type='medium-purple'
                label='Create New'
                icon={<img src='/images/add-icon.svg' />}
                onClick={goToCreateCustomer}
              />
            </div>
          </div>
          <AllCustomersGrid />
        </React.Fragment>
      )}
    </Loader>
  );
};

export default AllCustomers;
