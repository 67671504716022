import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const CssButtonLarge = styled(Button)(({ theme }) => ({
  backgroundColor: '#473ab7', // set the background color
  color: '#ffffff', // set the font color
  fontSize: '22px', // set the font size
  fontWeight: 600,
  textTransform: 'capitalize',
  padding: '10px',
  width: '100%',
  boxShadow: 'none',

  '&:hover': {
    backgroundColor: '#18277a',
    color: '#ffffff',
  },
  borderRadius: '8px',
}));

const CssButtonMedium = styled(Button)(({ theme }) => ({
  backgroundColor: '#473ab7', // set the background color
  color: '#ffffff', // set the font color
  fontSize: '14px', // set the font size
  fontWeight: 700,
  lineHeight: '21px',
  textTransform: 'capitalize',
  padding: '6px',
  width: '100%',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: '#18277a',
    color: '#ffffff',
  },
  borderRadius: '6px',
}));

const CssButtonMediumOutline = styled(Button)(({ theme }) => ({
  backgroundColor: 'transparent', // set the background color
  color: '#473ab7', // set the font color
  fontSize: '14px', // set the font size
  fontWeight: 700,
  lineHeight: '21px',
  textTransform: 'capitalize',
  padding: '6px',
  width: '100%',
  boxShadow: 'none',
  borderRadius: '6px',
  border: '1px solid #473ab7',
}));

const CssButtonMediumGrey = styled(Button)(({ theme }) => ({
  backgroundColor: '#D9D9D9', // set the background color
  color: '#000000', // set the font color
  fontSize: '12px', // set the font size
  fontWeight: 500,
  textTransform: 'capitalize',
  padding: '6px',
  width: '100%',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: '#9e9d9d',
    color: '#ffffff',
  },
  borderRadius: '6px',
}));

const CssButtonMediumLight = styled(Button)(({ theme }) => ({
  background: '#FFFFFF', // set the background color
  color: '#8960D2', // set the font color
  fontSize: '12px', // set the font size
  fontWeight: 700,
  textTransform: 'capitalize',
  padding: '10px',
  boxShadow: 'none',
  width: '100%',
  '&:hover': {
    backgroundColor: '#18277a',
    color: '#ffffff',
  },
  borderRadius: '10px',
}));

const CustomButton = ({ label, type, icon, customStyle, disabled, onClick }) => {
  if (type === 'large')
    return (
      <CssButtonLarge startIcon={icon} variant='contained' onClick={onClick}>
        {label}
      </CssButtonLarge>
    );
  else if (type === 'medium')
    return (
      <CssButtonMedium
        disabled={disabled}
        sx={customStyle}
        startIcon={icon}
        variant='contained'
        onClick={onClick}
      >
        {label}
      </CssButtonMedium>
    );
  else if (type === 'medium-purple')
    return (
      <CssButtonMedium
        disabled={disabled}
        startIcon={icon}
        variant='contained'
        sx={{
          background: '#473AB7',
          ...(customStyle && { ...customStyle }),
        }}
        onClick={onClick}
      >
        {label}
      </CssButtonMedium>
    );
  else if (type === 'medium-grey')
    return (
      <CssButtonMediumGrey
        disabled={disabled}
        startIcon={icon}
        variant='contained'
        sx={customStyle}
        onClick={onClick}
      >
        {label}
      </CssButtonMediumGrey>
    );
  else if (type === 'medium-light')
    return (
      <CssButtonMediumLight
        disabled={disabled}
        startIcon={icon}
        variant='contained'
        onClick={onClick}
      >
        {label}
      </CssButtonMediumLight>
    );
  else if (type === 'medium-outline')
    return (
      <CssButtonMediumOutline
        disabled={disabled}
        startIcon={icon}
        sx={customStyle}
        variant='outlined'
        onClick={onClick}
      >
        {label}
      </CssButtonMediumOutline>
    );
};

export default CustomButton;
