import { useNavigate } from "react-router-dom";

const EmptyInvoices = () => {
  const navigate = useNavigate();

  const goToCreateInvoice = () => {
    navigate('/invoices/create');
  };

  return (
    <div className='d-flex justify-content-center align-items-center mt-5'>
      <div>
        <img src='/images/empty-graphic.svg' />
        <p
          className='text-center mt-5 mb-0'
          style={{
            fontSize: '26px',
            fontWeight: 600,
            color: '#081159'
          }}
        >
          We're in business!
        </p>
        <p
          className='text-center'
          style={{
            fontSize: '20px',
            fontWeight: 400,
            color: '#081159'
          }}
        >
          <span
            className='text-center'
            style={{
              fontSize: '20px',
              fontWeight: 400,
              color: '#473AB7',
              cursor: 'pointer'
            }}
            onClick={goToCreateInvoice}
          >
            Create your first invoice
          </span>
          &nbsp;to start getting paid.
        </p>
      </div>
    </div>
  );
};

export default EmptyInvoices;
