import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/@generalComponents/Loader';
import Input from '../components/@generalComponents/Input';
import { Button, Divider } from '@mui/material';
import CustomButton from '../components/@generalComponents/CustomButton';
import unicoAxios from '../utils/unicoAxios';
import { getCurrentUser } from '../redux/actions/user.actions';

const AccountDetails = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState({
    resetPassword: false,
    downgrade: false
  });
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  const currentUser = useSelector((state) => state.userReducer.currentUser);
  const userPlan = currentUser?.user?.plan;

  useEffect(() => {
    if (!currentUser) return;
    setFormState({
      firstName: currentUser?.user?.firstName,
      lastName: currentUser?.user?.lastName,
      email: currentUser?.user?.email,
    });
  }, [currentUser]);

  const changeHandler = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const saveChanges = async () => {
    await unicoAxios({
      method: 'PATCH',
      url: 'users/me',
      body: {
        firstName: formState.firstName,
        lastName: formState.lastName,
      },
      loadingMessage: 'Updating user details...',
      successMessage: 'User updated successfully.',
      onSuccess: () => dispatch(getCurrentUser()),
    });
  };

  const checkOutBusinessPlan = async () => {
    await unicoAxios({
      method: 'POST',
      url: 'payments/subscribe',
      body: {
        priceId: process.env.REACT_APP_BUSINESS_PLAN_PRICE_ID,
        successUrl: '/account',
        cancelUrl: '/account',
      },
      loadingMessage: 'Stay with us while we check you out...',
      onSuccess: ({ url }) => (window.location.href = url),
    });
  };

  const resetPassword = async () => {
    setLoading({ ...loading, resetPassword: true });
    await unicoAxios({
      method: 'POST',
      url: 'users/forgot-password',
      body: {
        email: formState.email,
      },
      loadingMessage: 'Sending you a reset password link...',
      successMessage: 'Successful! Please follow the instructions in the email to reset your password.'
    });
    setLoading({ ...loading, resetPassword: false });
  };

  const downgradeUser = async () => {
    setLoading({ ...loading, downgrade: true });
    await unicoAxios({
      method: 'DELETE',
      url: 'payments/unsubscribe',
      loadingMessage: 'Downgrading account...',
      successMessage: 'Account downgraded successfully.',
      onSuccess: () => {
        dispatch(getCurrentUser());
      }
    });
    setLoading({ ...loading, downgrade: false });
  }

  return (
    <Loader renderChildren={currentUser}>
      <div className='d-flex justify-content-between align-items-center'>
        <h1 className='heading-primary mb-0 mt-0'>My Account</h1>
        <Button
          onClick={resetPassword}
          disabled={loading.resetPassword}
          style={{
            background: '#F7F7F7',
            height: '39px',
            color: '#374151',
            fontSize: '12px',
            fontWeight: '600',
            width: '150px',
            textTransform: 'none'
          }}
        >
          Reset Password
        </Button>
      </div>
      <div className='invoice-card'>
        <h4 className='heading-secondary mb-0'>Personal Details</h4>
        <p className='subtitle-secondary mb-5'>
          This information is associated with your Unico Invoicing
          online account - it does not affect the details in any business
        </p>
        <div className='row'>
          <div className='col-md-4 mb-4'>
            <Input
              type='text'
              label='First Name'
              name='firstName'
              value={formState.firstName}
              onChange={changeHandler}
            />
          </div>
          <div className='col-md-4 mb-4'>
            <Input
              type='text'
              label='Last Name'
              name='lastName'
              value={formState.lastName}
              onChange={changeHandler}
            />
          </div>
          <div className='col-md-4 mb-4'>
            <Input
              type='text'
              label='Email'
              name='email'
              value={formState.email}
              onChange={changeHandler}
              disabled={true}
            />
          </div>
        </div>
        <CustomButton
          type='medium'
          label='Save'
          customStyle={{
            height: '45px',
            fontSize: '20px'
          }}
          onClick={saveChanges}
        />
        <Divider
          sx={{
            backgroundColor: '#d9d9d9',
            height: 2,
            marginTop: '35px',
            marginBottom: '35px'
          }}
        />
        <h4 className='heading-secondary mb-4'>
          My Subscription
        </h4>
        <div className='row'>
          <div className='col-md-4 mb-4 mb-md-0'>
            <div className='upgrade-plan-price-container'>
              <p className='title'>FREE</p>
              <div className='d-flex align-items-center'>
                <h1 className='value mb-0'>£0</h1>
                <p className='freq mt-3'>/month</p>
              </div>
              <ul>
                <li className='price-features'>Unlimited Customers</li>
                <li className='price-features'>Up to 3 Invoices</li>
                <li className='price-features'>
                  Summary Reports
                  <span className='coming-soon'>
                    Coming Soon
                  </span>
                </li>
                <li className='price-features strike'>
                  Estimates
                  <span className='coming-soon'>
                    Coming Soon
                  </span>
                </li>
                <li className='price-features strike'>
                  Due-Date Reminders
                  <span className='coming-soon'>
                    Coming Soon
                  </span>
                </li>
                <li className='price-features strike'>
                  Recurring Invoices
                  <span className='coming-soon'>
                    Coming Soon
                  </span>
                </li>
                <li className='price-features strike'>
                  Schedule Invoices
                  <span className='coming-soon'>
                    Coming Soon
                  </span>
                </li>
              </ul>
              <CustomButton
                label={userPlan == 0 ? 'Active' : 'Downgrade'}
                type='medium-outline'
                disabled={userPlan === 0 || loading.downgrade}
                onClick={downgradeUser}
              />
            </div>
          </div>
          <div className='col-md-4 mb-4 mb-md-0'>
            <div className='upgrade-plan-price-container business'>
              <p className='title'>BUSINESS</p>
              <div className='d-flex align-items-center'>
                <h1 className='value mb-0'>£4</h1>
                <p className='decimal'>.99</p>
                <p className='freq mt-3'>/month</p>
              </div>
              <ul>
                <li className='price-features'>Unlimited Customers</li>
                <li className='price-features'>Unlimited Invoices</li>
                <li className='price-features'>
                  Advanced Reports
                  <span className='coming-soon'>
                    Coming Soon
                  </span>
                </li>
                <li className='price-features'>
                  Estimates
                  <span className='coming-soon'>
                    Coming Soon
                  </span>
                </li>
                <li className='price-features'>
                  Due-Date Reminders
                  <span className='coming-soon'>
                    Coming Soon
                  </span>
                </li>
                <li className='price-features strike'>
                  Recurring Invoices
                  <span className='coming-soon'>
                    Coming Soon
                  </span>
                </li>
                <li className='price-features strike'>
                  Schedule Invoices
                  <span className='coming-soon'>
                    Coming Soon
                  </span>
                </li>
              </ul>
              <CustomButton
                label={userPlan == 0 ? 'Upgrade' : 'Active'}
                type='medium-purple'
                disabled={userPlan === 1}
                onClick={checkOutBusinessPlan}
              />
            </div>
          </div>
          <div className='col-md-4 mb-4 mb-md-0'>
            <div className='upgrade-plan-price-container'>
              <p className='title'>PREMIUM</p>
              <div className='d-flex align-items-center'>
                <h1 className='value mb-0'>£9</h1>
                <p className='decimal'>.99</p>
                <p className='freq mt-3'>/month</p>
              </div>
              <ul>
                <li className='price-features'>Unlimited Customers</li>
                <li className='price-features'>Unlimited Invoices</li>
                <li className='price-features'>
                  Advanced Reports
                  <span className='coming-soon'>
                    Coming Soon
                  </span>
                </li>
                <li className='price-features'>
                  Estimates
                  <span className='coming-soon'>
                    Coming Soon
                  </span>
                </li>
                <li className='price-features'>
                  Due-Date Reminders
                  <span className='coming-soon'>
                    Coming Soon
                  </span>
                </li>
                <li className='price-features'>
                  Recurring Invoices
                  <span className='coming-soon'>
                    Coming Soon
                  </span>
                </li>
                <li className='price-features'>
                  Schedule Invoices
                  <span className='coming-soon'>
                    Coming Soon
                  </span>
                </li>
              </ul>
              <CustomButton disabled={true} label='COMING SOON' type='medium-outline' />
            </div>
          </div>
        </div>
      </div>
    </Loader>
  );
};

export default AccountDetails;
