import { Divider, IconButton } from '@mui/material';
import Input from '../../components/@generalComponents/Input';
import React from 'react';
import { invoiceStatuses } from '../../exports/commonExports';
import CustomButton from '../@generalComponents/CustomButton';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { modalAddPayment } from '../../redux/actions/modal.actions';
import { getSingleInvoice } from '../../redux/actions/invoice.actions';
import unicoAxios from '../../utils/unicoAxios';

const InvoiceAdditionalDetails = ({ invoiceDetails }) => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.userReducer.currentUser);

  const openAddPaymentModal = () => {
    dispatch(
      modalAddPayment({
        isOpen: true,
        invoiceId: invoiceDetails?.invoiceId,
        invoiceNumber: invoiceDetails?.invoiceNumber,
        onComplete: () =>
          dispatch(
            getSingleInvoice(
              currentUser?.business?.businessId,
              invoiceDetails?.invoiceId
            )
          ),
      })
    );
  };

  const removePaymentOnInvoice = async () => {
    await unicoAxios({
      method: 'PATCH',
      url: `invoices/${currentUser?.business?.businessId}/${invoiceDetails?.invoiceId}`,
      body: {
        isPaid: false,
        paymentDate: null,
        status: invoiceStatuses.SENT,
      },
      loadingMessage: 'Removing payment on invoice...',
      successMessage: 'Payment removed successfully!',
    });
    dispatch(
      getSingleInvoice(
        currentUser?.business?.businessId,
        invoiceDetails?.invoiceId
      )
    );
  };

  const markInvoiceAsSent = async () => {
    await unicoAxios({
      method: 'PATCH',
      url: `invoices/${currentUser?.business?.businessId}/${invoiceDetails?.invoiceId}`,
      body: {
        status: invoiceStatuses.SENT,
      },
      loadingMessage: 'Marking invoice as sent...',
      successMessage: 'Invoice marked as sent successfully!',
    });
    dispatch(
      getSingleInvoice(
        currentUser?.business?.businessId,
        invoiceDetails?.invoiceId
      )
    );
  };

  const revertToDraft = async () => {
    await unicoAxios({
      method: 'PATCH',
      url: `invoices/${currentUser?.business?.businessId}/${invoiceDetails?.invoiceId}`,
      body: {
        status: invoiceStatuses.DRAFT,
      },
      loadingMessage: 'Reverting invoice to draft...',
      successMessage: 'Invoice marked as draft successfully!',
    });
    dispatch(
      getSingleInvoice(
        currentUser?.business?.businessId,
        invoiceDetails?.invoiceId
      )
    );
  };

  return (
    <div className='invoice-card align-self-start'>
      <h1 className='heading-secondary-invoice mb-0'>Other Details</h1>
      <Divider
        sx={{
          backgroundColor: '#d9d9d9',
          height: 1,
          marginTop: '10px',
          marginBottom: 2,
        }}
      />

      {!invoiceDetails?.invoiceId ? (
        <div className='d-flex align-items-center'>
          <img src='/images/invoice-draft-icon.svg' width={40} height={40} />
          <div className='ms-2'>
            <p className='invoice-status-text'>Creating New Invoice</p>
          </div>
        </div>
      ) : invoiceDetails?.status == invoiceStatuses.DRAFT ? (
        <div className='d-flex align-items-center'>
          <img src='/images/invoice-draft-icon.svg' width={40} height={40} />
          <div className='ms-2'>
            <p className='invoice-status-text'>Invoice In Draft</p>
            <p
              className='invoice-status-subtext'
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={markInvoiceAsSent}
            >
              Mark invoice as sent?
            </p>
          </div>
        </div>
      ) : invoiceDetails?.status == invoiceStatuses.SENT ? (
        <div>
          <div className='d-flex align-items-center mb-2'>
            <img src='/images/invoice-sent-icon.svg' width={40} height={40} />
            <div className='ms-2'>
              <p className='invoice-status-text'>Invoice Unpaid</p>
              <p className='invoice-status-subtext'>
                Due in {moment(invoiceDetails?.dueDate).diff(moment(), 'days')}{' '}
                days.
              </p>
            </div>
          </div>
          {invoiceDetails?.sentVia === 'MANUAL' ?
            <p className='invoice-status-subtext mb-3'>
              This invoice was marked as manually sent.
              If this was a mistake,&nbsp;
              <span className='color-secondary hover-on' onClick={revertToDraft}>
                revert to draft.
              </span>
            </p> : invoiceDetails?.sentVia === 'EMAIL' ?
              <p className='invoice-status-subtext mb-3'>
                This invoice was automatically sent via email.
                Last sent on&nbsp;
                <span className='color-secondary'>
                  {moment(invoiceDetails?.latestEmailSentOn).format('DD/MM/YY')}
                </span>
              </p> : null
          }
          <CustomButton
            label='Add Payment'
            type='medium-purple'
            onClick={openAddPaymentModal}
          />
        </div>
      ) : invoiceDetails?.status == invoiceStatuses.PAID ? (
        <div>
          <div className='d-flex align-items-center mb-3'>
            <img src='/images/invoice-paid-icon.svg' width={40} height={40} />
            <div className='ms-2'>
              <p className='invoice-status-text'>Invoice Paid</p>
              <p className='invoice-status-subtext'>
                {moment(invoiceDetails?.paymentDate).format('DD/MM/YYYY')}
              </p>
            </div>
          </div>
          <CustomButton
            label='Remove Payment'
            type='medium-purple'
            onClick={removePaymentOnInvoice}
          />
        </div>
      ) : null
      }
      {/* <p
        className='mt-3 mb-2'
        style={{
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '20px',
          color: '#374151',
        }}
      >
        Private Notes
      </p>
      <Input
        type='textarea'
        name='privateNotes'
        value={invoiceDetails?.privateNotes}
        rows={3}
      />
      <p className='mt-3 mb-0 invoice-coming-soon'>
        Recurring invoices coming soon!
      </p> */}
    </div >
  );
};

export default InvoiceAdditionalDetails;
