import React, { useEffect, useState } from 'react';
import MainLayout from '../layouts/MainLayout';
import { useSelector } from 'react-redux';
import moment from 'moment';
import unicoAxios from '../utils/unicoAxios';
import Loader from '../components/@generalComponents/Loader';
import InvoicesCard from '../components/Dashboard/InvoicesCard';
import CustomersCard from '../components/Dashboard/CustomersCard';
import RecentInvoicesGrid from '../components/grids/RecentInvoicesGrid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import OverdueInvoicesGrid from '../components/grids/OverdueInvoicesGrid';
import { Chip } from '@mui/material';
import EmptyRecentInvoices from '../components/@generalComponents/EmptyRecentInvoices';
import CustomButton from '../components/@generalComponents/CustomButton';
import { useNavigate } from 'react-router-dom';

const getGreeting = () => {
  const hour = moment().hour();
  if (hour >= 0 && hour < 12) {
    return 'Good morning, ';
  } else if (hour >= 12 && hour < 18) {
    return 'Good afternoon, ';
  } else {
    return 'Good evening, ';
  }
};

const Dashboard = () => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const [statistics, setStatistics] = useState();

  const currentUser = useSelector((state) => state.userReducer.currentUser);
  const customers = useSelector((state) => state.customerReducer.allCustomers);

  useEffect(() => {
    if (currentUser) getStatistics();
  }, [currentUser]);

  const getStatistics = async () => {
    const { statistics } = await unicoAxios({
      method: 'GET',
      url: `invoices/statistics/${currentUser?.business?.businessId}`,
    });
    setStatistics(statistics);
  };

  const createNewInvoice = () => {
    navigate('/invoices/create')
  }

  return (
    <Loader renderChildren={statistics && customers}>
      <div className='d-md-none d-inline'>
        <p className='dashboard-greeting-text'>
          {getGreeting() +
            currentUser?.user?.firstName +
            ' ' +
            currentUser?.user?.lastName}
        </p>
        <p className='dashboard-greeting-title'>Welcome to Unico Invoicing</p>
      </div>
      <div className='bg-white d-md-none d-block mt-4 mb-3' style={{ padding: '15px 20px' }}>
        <p
          style={{
            fontSize: '14px',
            fontWeight: 700,
            color: '#081159',
            marginBottom: '5px'
          }}
        >
          {currentUser?.business?.name}
        </p>
        <CustomButton
          label='Edit Business'
          type='medium'
          onClick={() => navigate('/edit-business')}
          customStyle={{
            fontSize: '12px',
            padding: '3px'
          }}
        />
      </div>
      <div className='welcome-box purple-bg d-none d-md-flex align-items-center mb-4'>
        <img src='/images/unico-logo-small.svg' width={92} height={90} />
        <div className='ms-4'>
          <p className='dashboard-greeting-text'>
            {getGreeting() +
              currentUser?.user?.firstName +
              ' ' +
              currentUser?.user?.lastName}
          </p>
          <p className='dashboard-greeting-title'>Welcome to Unico Invoicing</p>
        </div>
      </div>
      <div className='row d-flex d-md-none g-2'>
        <div className='col-6'>
          <InvoicesCard mobile totalInvoices={statistics?.totalInvoices} />
        </div>
        <div className='col-6'>
          <CustomersCard mobile />
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col-6'>
          <h3 className='dashboard-heading' style={isMediumScreen ? { fontSize: 18 } : null}>Recent Invoices</h3>
        </div>
        <div className='col-6 d-flex justify-content-end'>
          {isMediumScreen &&
            <CustomButton
              type='medium-purple'
              label='Create New'
              // icon={<img src='/images/add-icon.svg' />}
              customStyle={{ fontSize: 12, width: '68%' }}
              onClick={createNewInvoice}
            />}
        </div>

      </div>
      <div className='row mt-2'>
        <div className='col-md-9'>
          {statistics?.recentInvoices?.length > 0 ?
            <div className={`${isMediumScreen && 'ps-3'}`}>
              <RecentInvoicesGrid invoices={statistics?.recentInvoices} />
            </div> : <EmptyRecentInvoices />
          }
        </div>
        <div className='col-3 d-none d-md-flex flex-column justify-content-between'>
          <InvoicesCard totalInvoices={statistics?.totalInvoices} />
          <CustomersCard />
        </div>
      </div>
      {statistics?.recentInvoices?.length > 0 &&
        <React.Fragment>
          <h3 className='dashboard-heading mt-5' style={isMediumScreen ? { fontSize: 18 } : null}>OVERDUE Invoices</h3>
          <div className='row mt-2'>
            <div className='col-12'>
              <div className={`${isMediumScreen && 'ps-3'}`}>
                {statistics?.overdueInvoices?.length > 0 ?
                  <OverdueInvoicesGrid invoices={statistics?.overdueInvoices} />
                  : <p className='text-center mt-3'>No invoices are overdue.</p>
                }
              </div>
            </div>
          </div>
        </React.Fragment>
      }
    </Loader>
  );
};

export default Dashboard;
