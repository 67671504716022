import React, { useState } from 'react';
import Input from '../components/@generalComponents/Input';
import CustomButton from '../components/@generalComponents/CustomButton';
import { useNavigate } from 'react-router-dom';
import unicoAxios from '../utils/unicoAxios';
import { useDispatch } from 'react-redux';
import { getCurrentUser } from '../redux/actions/user.actions';

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formState, setFormState] = useState();

  const changeHandler = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const goToSignIn = () => {
    navigate('/sign-in');
  };

  const onRegistrationSuccess = (successData) => {
    localStorage.setItem('accessToken', successData?.accessToken);
    dispatch(getCurrentUser());
    navigate(`/create-business/${successData?.user?.userId}`);
  };

  const handleRegister = async () => {
    await unicoAxios({
      method: 'POST',
      url: 'users/register',
      body: formState,
      noAuth: true,
      loadingMessage: 'Creating account...',
      successMessage: 'Account created successfully!',
      onSuccess: onRegistrationSuccess,
    });
  };

  return (
    <div className='container-fluid'>
      <div className='row login-holder'>
        <div className='col-lg-5 d-flex align-items-center justify-content-center login-form'>
          <div className='row ps-lg-5 pe-lg-5'>
            <div className='d-flex justify-content-center'>
              <img
                className='register-form-logo'
                src='/images/unico-logo-large.svg'
              />
            </div>
            <h1 className='text-center mt-4 mb-5'>Create Account</h1>
            <div className='col-6'>
              <Input
                type='text'
                label='First Name'
                placeholder='John'
                name='firstName'
                value={formState?.firstName}
                onChange={changeHandler}
              />
            </div>
            <div className='col-6'>
              <Input
                type='text'
                label='Last Name'
                placeholder='Doe'
                name='lastName'
                value={formState?.lastName}
                onChange={changeHandler}
              />
            </div>
            <div className='col-12 mt-4'>
              <Input
                type='text'
                startIcon='/images/email-icon.svg'
                placeholder='Email'
                name='email'
                value={formState?.email}
                onChange={changeHandler}
              />
            </div>
            <div className='col-12 mt-4 mb-3'>
              <Input
                type='password'
                startIcon='/images/password-key.svg'
                placeholder='Password'
                name='password'
                value={formState?.password}
                onChange={changeHandler}
              />
            </div>
            <div className='col-12 mt-4'>
              <CustomButton
                type='large'
                label='Register'
                onClick={handleRegister}
              />
            </div>
            <div className='col-12 mt-4 d-flex justify-content-end'>
              <div className='d-flex'>
                <p className='color-primary me-1'>Already have an account? </p>
                <p className='color-secondary hover-on' onClick={goToSignIn}>
                  Login
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-7 d-none d-lg-flex login-graphic justify-content-center align-items-center'>
          <img
            className='login-form-graphic-2'
            src='/images/register-graphic-1.svg'
          />
        </div>
      </div>
    </div>
  );
};

export default Register;
