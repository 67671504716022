import { Avatar, Chip, Divider } from '@mui/material';
import React, { useEffect } from 'react';
import CustomButton from '../@generalComponents/CustomButton';
import {
  sidebarActiveNavLinks,
  sidebarInactiveNavLinks,
  sidebarNavLinks,
  sidebarSupportLinks,
} from '../../exports/commonExports';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const MobileSidebar = ({ closeSidebar }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const currentUser = useSelector((state) => state.userReducer.currentUser);

  return (
    <div className='mobile-sidebar-root'>
      <div className='mobile-sidebar-links'>
        {sidebarActiveNavLinks?.map((link, index) => {
          return (
            <div
              className={`mobile-sidebar-link-item ${pathname.includes(link.link) ? 'active' : ''
                }`}
              key={index}
              onClick={() => {
                navigate(link.link);
                closeSidebar();
              }}
            >
              <img
                src={
                  pathname.includes(link.link) ? link?.icon : link?.mobileIcon
                }
                width='30px'
                height='30px'
                alt={link.name}
              />
              <p
                className={`mobile-sidebar-link-name ${pathname.includes(link.link) ? 'active' : ''
                  }`}
              >
                {link.name}
              </p>
            </div>
          );
        })}
      </div>
      <Divider sx={{ backgroundColor: '#d9d9d9', height: 2 }} />
      <div className='mobile-sidebar-links mt-3'>
        {sidebarInactiveNavLinks?.map((link, index) => {
          if (link.name !== 'Items')
            return (
              <div
                className='mobile-sidebar-link-item'
                key={index}
                style={
                  pathname.includes(link.link)
                    ? {
                      background: 'rgba(255, 255, 255, 0.1)',
                    }
                    : null
                }
              >
                <img src={link?.mobileIcon} alt={link.name} />
                <div>
                  <p className='mobile-sidebar-link-name'>{link.name}</p>
                  <p className='mobile-sidebar-link-subtext'>Coming Soon</p>
                </div>
              </div>
            );
        })}
      </div>
      <Divider sx={{ backgroundColor: '#d9d9d9', height: 2 }} />

      <div className='mobile-sidebar-links mt-3'>
        {sidebarSupportLinks?.map((link, index) => {
          return (
            <div className='mobile-sidebar-link-item' key={index}>
              <p
                className='mobile-sidebar-link-name m-0'
                style={{ fontSize: '15px' }}
              >
                {link.name}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(MobileSidebar);
