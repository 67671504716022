export const MODAL_CONFIRMATION = 'MODAL_CONFIRMATION';
export const MODAL_ADD_PAYMENT = 'MODAL_ADD_PAYMENT';
export const MODAL_NO_CUSTOMERS = 'MODAL_NO_CUSTOMERS';
export const MODAL_UPGRADE_SUCCESS = 'MODAL_UPGRADE_SUCCESS';

export const modalConfirmation = (payload) => ({
  type: MODAL_CONFIRMATION,
  payload,
});

export const modalAddPayment = (payload) => ({
  type: MODAL_ADD_PAYMENT,
  payload,
});

export const modalNoCustomers = (payload) => ({
  type: MODAL_NO_CUSTOMERS,
  payload,
});

export const modalUpgradeSuccess = (payload) => ({
  type: MODAL_UPGRADE_SUCCESS,
  payload,
});
