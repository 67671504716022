import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { TableCell, TableRow } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import OverdueInvoicesRow from './rows/OverdueInvoicesRow';

const OverdueInvoicesGrid = ({ invoices }) => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(
      invoices?.map((invoice) => {
        return {
          id: invoice?.invoiceId,
          ...invoice,
        };
      })
    );
  }, [invoices]);

  return (
    <TableContainer>
      <Table aria-label='collapsible table'>
        <TableHead>
          <TableRow>
            {!isMediumScreen && (
              <React.Fragment>
                <TableCell style={{ width: '10%' }}>Invoice ID</TableCell>
                <TableCell>Date</TableCell>
              </React.Fragment>
            )}
            <TableCell>Customer</TableCell>
            <TableCell>Total</TableCell>
            <TableCell>Overdue</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row) => (
            <OverdueInvoicesRow key={row.id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OverdueInvoicesGrid;
