import { Chip } from '@mui/material';
import React from 'react';

const CustomChip = ({ type, label, width }) => {
  if (type === 'danger')
    return (
      <Chip
        label={label}
        sx={{
          width: width || '60%',
          backgroundColor: '#FFE6EF', // Change the background color
          color: '#972B4F', // Change the font color
          fontSize: '10px', // Change the font size
          textTransform: 'uppercase', // Change the text transform
          borderRadius: '5px', // Change the border radius
        }}
      />
    );
  else if (type === 'warning')
    return (
      <Chip
        label={label}
        sx={{
          width: width || '60%',
          background: 'rgba(71, 58, 183, 0.10)',
          color: '#473AB7', // Change the font color
          fontSize: '10px', // Change the font size
          textTransform: 'uppercase', // Change the text transform
          borderRadius: '5px', // Change the border radius
        }}
      />
    );
  else
    return (
      <Chip
        label={label}
        sx={{
          width: width || '60%',
          backgroundColor: '#EBF8F1', // Change the background color
          color: '#449271', // Change the font color
          fontSize: '10px', // Change the font size
          textTransform: 'uppercase', // Change the text transform
          borderRadius: '5px', // Change the border radius
        }}
      />
    );
};

export default CustomChip;
