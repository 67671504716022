import React, { useState } from 'react';
import Input from '../components/@generalComponents/Input';
import CustomButton from '../components/@generalComponents/CustomButton';
import { useNavigate } from 'react-router-dom';
import unicoAxios from '../utils/unicoAxios';
import { useDispatch } from 'react-redux';
import { getCurrentUser } from '../redux/actions/user.actions';

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formState, setFormState] = useState({
    email: '',
    password: '',
    rememberMe: true
  });

  const changeHandler = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const goToRegistration = () => {
    navigate('/register');
  };

  const onSignInSuccess = (successData) => {
    const { accessToken, user, business } = successData;
    if (formState.rememberMe) {
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('currentUser', JSON.stringify({ user, business }));
    } else {
      sessionStorage.setItem('accessToken', accessToken);
      sessionStorage.setItem('currentUser', JSON.stringify({ user, business }));
    }
    dispatch(getCurrentUser());
    if (business) {
      navigate('/dashboard');
    } else {
      navigate(`/create-business/${user?.userId}`);
    }
  };

  const handleSignIn = async () => {
    await unicoAxios({
      method: 'POST',
      url: 'users/sign-in',
      body: {
        email: formState.email,
        password: formState.password,
      },
      noAuth: true,
      loadingMessage: 'Signing in...',
      successMessage: 'Signed in successfully',
      onSuccess: onSignInSuccess,
    });
  };

  return (
    <div className='container-fluid'>
      <div className='row login-holder'>
        <div className='col-lg-5 d-flex align-items-center justify-content-center login-form'>
          <div className='row ps-lg-5 pe-lg-5'>
            <div className='d-flex justify-content-center'>
              <img
                className='login-form-logo'
                src='/images/unico-logo-large.svg'
              />
            </div>
            <div className='d-flex d-lg-none justify-content-center'>
              <img
                className='login-form-graphic-1-mobile'
                src='/images/login-graphic-1-mobile.svg'
              />
            </div>
            <div className='col-12'>
              <Input
                type='text'
                name='email'
                startIcon='/images/email-icon.svg'
                placeholder='Email'
                onChange={changeHandler}
              />
            </div>
            <div className='col-12 mt-4 mb-3'>
              <Input
                type='password'
                name='password'
                startIcon='/images/password-key.svg'
                placeholder='Password'
                onChange={changeHandler}
              />
            </div>
            <div className='col-12 d-flex justify-content-between'>
              <div className='d-flex'>
                <Input
                  type='checkbox'
                  value={formState?.rememberMe}
                  onChange={() => setFormState({ ...formState, rememberMe: !formState.rememberMe })}
                />
                <p
                  className='prevent-select hover-on color-primary mt-3 normal-text'
                  onClick={() => setFormState({ ...formState, rememberMe: !formState.rememberMe })}
                >
                  Remember me
                </p>
              </div>
              <p
                className='color-secondary hover-on mt-3'
                onClick={() => navigate('/forgot-password')}
              >
                Forgot Password?
              </p>
            </div>
            <div className='col-12 mt-4'>
              <CustomButton
                type='large'
                label='Log In'
                onClick={handleSignIn}
              />
            </div>
            <div className='col-12 mt-4 d-flex justify-content-end'>
              <div className='d-flex'>
                <p className='color-primary me-1'>Don't have an account? </p>
                <p
                  className='color-secondary hover-on'
                  onClick={goToRegistration}
                >
                  Register
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-7 d-none d-lg-flex login-graphic justify-content-center align-items-center'>
          <img
            className='login-form-graphic-2'
            src='/images/login-graphic-2.svg'
          />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
