import React, { useEffect, useState } from 'react';
import AllInvoicesGrid from '../../components/grids/AllInvoicesGrid';
import unicoAxios from '../../utils/unicoAxios';
import { useSelector } from 'react-redux';
import Loader from '../../components/@generalComponents/Loader';
import EmptyInvoices from '../../components/@generalComponents/EmptyInvoices';

const AllInvoices = () => {

  const [invoiceCount, setInvoiceCount] = useState(null);

  const currentUser = useSelector((state) => state.userReducer.currentUser);

  useEffect(() => {
    if (!currentUser || invoiceCount != null) return;
    getInvoices();
  }, [currentUser, invoiceCount]);

  const getInvoices = async () => {
    const { totalRecords } = await unicoAxios({
      method: 'GET',
      url: `invoices/all/${currentUser?.business?.businessId}/0?timeZone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
    });
    setInvoiceCount(totalRecords);
  };

  return (
    <Loader renderChildren={invoiceCount != null}>
      <div className='d-flex align-items-center'>
        <img src='/images/unico-logo-small.svg' width={47} height={46} />
        <h1 className='heading-primary mb-0 mt-0 ms-3'>View Invoices</h1>
      </div>
      <p className='subtitle-primary'>
        Here is a list of your invoices. Use the filters to sort the section, or
        the check button on the right to record a payment
      </p>
      {invoiceCount == 0 ? <EmptyInvoices /> : <AllInvoicesGrid />}
    </Loader>
  );
};

export default AllInvoices;
