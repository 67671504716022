import React from 'react';
import Lottie from 'react-lottie';
import * as animationData from '../../assets/lotties/loading-lottie.json';
import * as animationDataTwo from '../../assets/lotties/loading-lottie-2.json';

const Loader = ({ renderChildren, children, type }) => {
  if (!renderChildren && type === 'secondary')
    return (
      <div className='d-flex justify-content-center align-items-center'>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: animationDataTwo,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          height={200}
          width={200}
          isClickToPauseDisabled
          speed={1}
        />
      </div>
    );
  else if (!renderChildren && type !== 'secondary')
    return (
      <div className='d-flex justify-content-center align-items-center'>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          height={400}
          width={400}
          isClickToPauseDisabled
          speed={3}
        />
      </div>
    );
  else return children;
};

export default Loader;
