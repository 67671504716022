import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/@generalComponents/Loader';
import unicoAxios from '../../utils/unicoAxios';
import { IconButton } from '@mui/material';
import CustomerInfo from '../../components/CustomerDetails/CustomerInfo';
import CustomerStats from '../../components/CustomerDetails/CustomerStats';
import CustomerInvoicesGrid from '../../components/grids/CustomerInvoicesGrid';

const CustomerDetails = () => {
  const { customerId } = useParams();
  const navigate = useNavigate();

  const [customerDetails, setCustomerDetails] = useState();

  useEffect(() => {
    getCustomerDetails();
  }, [customerId]);

  const getCustomerDetails = async () => {
    const { customer } = await unicoAxios({
      method: 'GET',
      url: `customers/${customerId}`,
    });
    setCustomerDetails(customer);
  };

  return (
    <Loader renderChildren={customerDetails}>
      <div className='row'>
        <div className='col-md-8 bg-white pt-4 ps-5 pb-4 pe-5'>
          <CustomerInfo customerDetails={customerDetails} />
        </div>
        <div className='col-md-4'>
          <CustomerStats customerDetails={customerDetails} />
        </div>
      </div>
      <div className='row'>
        <CustomerInvoicesGrid customerId={customerId} />
      </div>
    </Loader>
  );
};

export default CustomerDetails;
