import React, { useCallback } from 'react';
import Input from '../@generalComponents/Input';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { IconButton } from '@mui/material';
import getRandomString from '../../utils/getRandomString';
import { debounce } from 'lodash';

const InvoiceItems = ({
  businessVatRegistered,
  invoiceItems,
  setInvoiceItems,
  itemTotals,
  calculateTotals,
}) => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const debouncedCalculation = useCallback(
    debounce(() => calculateTotals(), 200),
    [invoiceItems]
  );

  const addNewItem = () => {
    setInvoiceItems([
      ...invoiceItems,
      {
        key: getRandomString(20),
        name: '',
        quantity: '',
        price: '',
        vat: 20,
      },
    ]);
  };

  const changeHandler = (e, key) => {
    const { name, value } = e.target;
    const newInvoiceItems = [...invoiceItems];
    const index = newInvoiceItems.findIndex((item) => item.key === key);
    newInvoiceItems[index][name] = value;
    if (name !== 'name') debouncedCalculation();
    setInvoiceItems(newInvoiceItems);
  };

  const removeItem = (itemKey) => {
    const updatedArray = invoiceItems?.filter(
      item => item?.key !== itemKey
    );
    setInvoiceItems(updatedArray);
  }

  if (businessVatRegistered) {
    return (
      <div className='row'>
        {invoiceItems?.map((item) => {
          return (
            <React.Fragment key={item?.key}>
              <div className='col-10 mt-3'>
                <Input
                  thin
                  type='text'
                  name='name'
                  value={item?.name}
                  placeholder='Item Name'
                  onChange={(e) => changeHandler(e, item?.key)}
                />
              </div>
              <div className='col-2 d-flex justify-content-center mt-3'>
                <IconButton
                  onClick={() => removeItem(item?.key)}
                  sx={{ borderRadius: '100px' }}
                  disabled={invoiceItems?.length == 1}
                >
                  <img width={20} src='/images/cross-icon.svg' />
                </IconButton>
              </div>
              <div className='col-md-3 col-6 mt-3'>
                <Input
                  thin
                  type='text'
                  name='price'
                  value={item?.price}
                  inputAdornment='£'
                  placeholder='Price'
                  onChange={(e) => changeHandler(e, item?.key)}
                />
              </div>
              <div className='col-md-3 col-6 mt-3'>
                <Input
                  thin
                  type='text'
                  name='quantity'
                  value={item?.quantity}
                  placeholder='Qty'
                  onChange={(e) => changeHandler(e, item?.key)}
                />
              </div>
              <div className='col-md-3 col-6 mt-3'>
                <Input
                  thin
                  type='select'
                  name='vat'
                  value={item?.vat}
                  placeholder='Qty'
                  options={[
                    { value: 20, label: '20%' },
                    { value: 10, label: '10%' },
                    { value: 5, label: '5%' },
                  ]}
                  onChange={(e) => changeHandler(e, item?.key)}
                />
              </div>
              <div className='col-md-3 col-6 mt-3'>
                <div className='invoice-item-total'>
                  £
                  {
                    itemTotals?.find((itemVal) => itemVal?.key === item?.key)
                      ?.total
                  }
                </div>
              </div>
            </React.Fragment>
          );
        })}
        <div className='mt-3'>
          <IconButton onClick={addNewItem} sx={{ borderRadius: '5px' }}>
            <p className='invoice-add-item mb-0'>Add Item +</p>
          </IconButton>
        </div>
      </div>
    );
  } else
    return (
      <div className='row'>
        {/* {!isMediumScreen && (
          <React.Fragment>
            <div className='col-6 invoice-item-title'>ITEM</div>
            <div className='col-2 invoice-item-title'>Price</div>
            <div className='col-2 invoice-item-title'>QTY</div>
            <div className='col-2 invoice-item-title'>Total</div>
          </React.Fragment>
        )} */}
        {invoiceItems?.map((item) => {
          return (
            <React.Fragment>
              <div className='col-md-10 mt-3'>
                <Input
                  thin
                  type='text'
                  name='name'
                  value={item?.name}
                  placeholder='Item Name'
                  onChange={(e) => changeHandler(e, item?.key)}
                />
              </div>
              <div className='col-2 d-flex justify-content-center mt-3'>
                <IconButton
                  onClick={() => removeItem(item?.key)}
                  sx={{ borderRadius: '100px' }}
                  disabled={invoiceItems?.length == 1}
                >
                  <img width={20} src='/images/cross-icon.svg' />
                </IconButton>
              </div>
              <div className='col-md-4 col-4 mt-3'>
                <Input
                  thin
                  type='text'
                  name='price'
                  value={item?.price}
                  placeholder='Price'
                  inputAdornment='£'
                  onChange={(e) => changeHandler(e, item?.key)}
                />
              </div>
              <div className='col-md-4 col-4 mt-3'>
                <Input
                  thin
                  type='text'
                  name='quantity'
                  value={item?.quantity}
                  placeholder='QTY'
                  onChange={(e) => changeHandler(e, item?.key)}
                />
              </div>
              <div className='col-md-4 col-4 mt-3 '>
                <div className='invoice-item-total'>
                  £
                  {
                    itemTotals?.find((itemVal) => itemVal?.key === item?.key)
                      ?.total
                  }
                </div>
              </div>
            </React.Fragment>
          );
        })}
        <div className='mt-3'>
          <IconButton onClick={addNewItem} sx={{ borderRadius: '5px' }}>
            <p className='invoice-add-item mb-0'>Add Item +</p>
          </IconButton>
        </div>
      </div>
    );
};

export default InvoiceItems;
