import { Divider } from '@mui/material';
import Input from '../../components/@generalComponents/Input';
import React, { useState } from 'react';
import { invoiceStatuses } from '../../exports/commonExports';
import CustomButton from '../@generalComponents/CustomButton';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import unicoAxios from '../../utils/unicoAxios';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleInvoice } from '../../redux/actions/invoice.actions';
import toast from 'react-hot-toast';

const InvoiceControls = ({
  invoiceDetails,
  setInvoiceDetails,
  createInvoice,
  updateInvoice,
  createInvoiceAndEmail,
}) => {
  const dispatch = useDispatch();
  const { invoiceId } = useParams();

  const [latestEmailSentAt, setLatestEmailSentAt] = useState();
  const [emailSending, setEmailSending] = useState(false);

  const currentUser = useSelector((state) => state.userReducer.currentUser);

  const downloadPdf = async () => {
    await updateInvoice();
    window.open(
      `${process.env.REACT_APP_SERVER_URL}invoices/download/${invoiceId}`,
      '_blank'
    );
  };

  const sendInvoice = async () => {
    if (latestEmailSentAt && moment(latestEmailSentAt).add(30, 'seconds').isAfter(moment())) {
      toast.error('Invoices can be emailed once per 30 seconds.');
      return;
    }
    setEmailSending(true);
    if (invoiceId) {
      await unicoAxios({
        method: 'POST',
        url: `invoices/email/${currentUser?.business?.businessId}/${invoiceId}`,
        body: {
          email: invoiceDetails?.emailTo,
          text: invoiceDetails?.emailMessage,
        },
        loadingMessage: 'Sending Invoice...',
        successMessage: 'Invoice sent successfully. It can take up to 5 minutes for this email to reach their inbox.',
      });
      dispatch(getSingleInvoice(currentUser?.business?.businessId, invoiceId));
    } else await createInvoiceAndEmail();
    setLatestEmailSentAt(new Date());
    setEmailSending(false);
  };

  return (
    <div className='invoice-card align-self-end mt-3'>
      <h1 className='heading-secondary-invoice mb-0'>Send Invoice</h1>
      <p className='invoice-control-input-label mt-3 mb-2'>Email to:</p>
      <Input
        type='text'
        thin={true}
        name='emailTo'
        value={invoiceDetails?.emailTo}
        onChange={(e) =>
          setInvoiceDetails({ ...invoiceDetails, emailTo: e.target.value })
        }
      />
      {/* <p className='invoice-control-input-label mt-3 mb-2'>
        Include message in email:
      </p>
      <Input
        type='text'
        thin
        name='emailMessage'
        value={invoiceDetails?.emailMessage}
        onChange={(e) =>
          setInvoiceDetails({ ...invoiceDetails, emailMessage: e.target.value })
        }
      // rows={1}
      /> */}
      <CustomButton
        label='Send Invoice'
        type='medium-purple'
        customStyle={{ marginTop: '12px', height: '45px' }}
        onClick={sendInvoice}
        disabled={emailSending}
      />
      {invoiceId ? (
        <div>
          <CustomButton
            label='Download PDF'
            type='medium-outline'
            onClick={downloadPdf}
            customStyle={{ marginTop: '12px', height: '45px' }}
          />
          <CustomButton
            label='Update'
            type='medium-outline'
            onClick={updateInvoice}
            customStyle={{ marginTop: '12px', height: '45px' }}
          />
        </div>
      ) : (
        <CustomButton
          label='Create As Draft'
          type='medium-outline'
          onClick={createInvoice}
          icon={<img src='/images/draft-icon.svg' width={20} height={20} />}
          customStyle={{ marginTop: '12px', height: '45px' }}
        />
      )}
    </div>
  );
};

export default InvoiceControls;
