import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import {
  Checkbox,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Input as MuiInput,
  Button,
  InputLabel,
} from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const CssTextField = styled(TextField, {
  shouldForwardProp: (props) =>
    props !== 'focusColor' &&
    props !== 'startIcon' &&
    props !== 'thin' &&
    props !== 'inputAdornment',
})((p) => ({
  width: '100%',
  '& .MuiInputLabel-root': {
    fontWeight: 500,
    fontFamily: 'Poppins',
  },
  // input label when focused
  '& label.Mui-focused': {
    color: p.focusColor,
  },
  // focused color for input with variant='standard'
  '& .MuiInput-underline:after': {
    borderBottomColor: p.focusColor,
  },
  // focused color for input with variant='filled'
  '& .MuiFilledInput-underline:after': {
    borderBottomColor: p.focusColor,
  },
  // focused color for input with variant='outlined'
  '& .MuiOutlinedInput-root': {
    paddingLeft: p.startIcon ? '30px' : '10px !important',
    paddingRight: '10px !important',
    '&.Mui-focused fieldset': {
      borderColor: p.focusColor,
    },
    backgroundColor: '#ffffff',
  },
  '& .MuiOutlinedInput-input': {
    fontSize: '14px',
    fontWeight: '500',
    fontFamily: 'Poppins',
    ...(p?.thin && { padding: '10px' }),
    ...(p?.ultraThin && { padding: '5px' }),
    ...(p?.customThickness && { padding: p?.customThickness }),
  },
  '& .MuiInputBase-input': {
    ...(p?.inputAdornment && {
      marginLeft: '-30px !important',
    }),
  },
  '& .MuiInputAdornment-root': {
    marginRight: '25px',
  },
}));

const CssCheckBox = styled(Checkbox)(({ theme }) => ({
  '&.Mui-checked': {
    color: '#473ab7',
  },
  '& .MuiSvgIcon-root': {
    fontSize: 30, // set the size of the checkbox icon
  },
  '&.Mui-checked .MuiSvgIcon-root': {
    fontSize: 30, // set the size of the selected checkbox icon
  },
}));

const DateIcon = () => {
  return <img src='/images/calendar-icon.svg' width={20} height={20} />;
};

const Input = ({
  name,
  label,
  type,
  thin,
  className,
  ultraThin,
  disabled,
  placeholder,
  startIcon,
  onChange,
  onBlur,
  value,
  options,
  innerInputSx,
  rows,
  maxRows,
  inputAdornment,
  customThickness
}) => {
  const dateInputRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);

  if (type === 'password')
    return (
      <CssTextField
        focusColor='#473AB7'
        id='outlined-basic'
        label={label}
        disabled={disabled}
        thin={thin}
        name={name}
        type={showPassword ? 'text' : 'password'}
        placeholder={placeholder}
        variant='outlined'
        onChange={onChange}
        value={value}
        startIcon
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <img src={startIcon} width={22} height={22} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                // aria-label='toggle password visibility'
                onClick={() => setShowPassword(!showPassword)}
                edge='end'
              >
                {showPassword ? (
                  <img
                    src='/images/password-visible-on.svg'
                    width={22}
                    height={22}
                  />
                ) : (
                  <img
                    src='/images/password-visible-off.svg'
                    width={22}
                    height={22}
                  />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          width: '100%',
        }}
      />
    );
  if (type === 'date')
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']}>
          <DatePicker
            value={dayjs(value)}
            format='DD/MM/YYYY'
            slotProps={{ textField: { size: 'small' } }}
            slots={{
              openPickerIcon: DateIcon,
            }}
            sx={{
              fontFamily: 'Poppins !important',
              width: '100%',
            }}
            onChange={onChange}
          />
        </DemoContainer>
      </LocalizationProvider>
    );
  else if (type === 'checkbox')
    return (
      <CssCheckBox
        label={label}
        checked={value}
        name={name}
        onChange={onChange}
        sx={{ padding: 0 }}
        onBlur={onBlur}
      />
    );
  else if (type === 'select')
    return (
      <CssTextField
        className={className}
        focusColor='#473AB7'
        id='outlined-basic'
        label={label}
        thin={thin}
        ultraThin={ultraThin}
        customThickness={customThickness}
        type='select'
        name={name}
        select
        disabled={disabled}
        // placeholder={placeholder}
        variant='outlined'
        InputProps={{
          ...(startIcon && {
            startAdornment: (
              <InputAdornment position='start'>
                <img src={startIcon} width={22} height={22} />
              </InputAdornment>
            ),
          }),
        }}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        sx={{
          width: '100%',
          '& .MuiOutlinedInput-root': {
            paddingRight: '0px !important',
            paddingLeft: '20px !important',
          },
        }}
      >
        <MenuItem value={null} disabled>
          {placeholder}
        </MenuItem>
        {options?.map((option) => {
          return <MenuItem value={option.value}>{option.label}</MenuItem>;
        })}
      </CssTextField>
    );
  else if (type === 'file')
    return (
      <Button
        variant='outlined'
        startIcon={<img src='/images/start-icon.svg' width={22} height={22} />}
        sx={{
          color: 'black',
          fontWeight: '500',
          fontFamily: 'Poppins',
          width: '100%',
          fontSize: '18px',
          borderColor: '#473AB7',
          textTransform: 'none',
          borderWidth: '1.5px',
          '&:hover': {
            borderColor: '#473AB7',
            color: 'black',
            fontWeight: '500',
            fontFamily: 'Poppins',
            width: '100%',
            fontSize: '18px',
            borderColor: '#473AB7',
            textTransform: 'none',
            borderWidth: '1.5px',
          },
        }}
      >
        {label}
        <input
          type='file'
          accept='image/*'
          style={{
            opacity: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            cursor: 'pointer',
          }}
          onChange={onChange}
        />
      </Button>
    );
  else
    return (
      <CssTextField
        focusColor='#473AB7'
        id='outlined-basic'
        disabled={disabled}
        label={label}
        type={type}
        thin={thin}
        name={name}
        placeholder={placeholder}
        variant='outlined'
        startIcon={startIcon}
        inputAdornment={inputAdornment}
        InputProps={{
          ...(startIcon && {
            startAdornment: (
              <InputAdornment position='start'>
                <img src={startIcon} width={22} height={22} />
              </InputAdornment>
            ),
          }),
          ...(inputAdornment && {
            startAdornment: (
              <InputAdornment position='start'>{inputAdornment}</InputAdornment>
            ),
          }),
          sx: innerInputSx,
        }}
        multiline={type === 'textarea'}
        rows={rows}
        maxRows={maxRows}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
      />
    );
};

export default Input;
