import React, { useState } from 'react';
import addCommas from '../../utils/addCommas';
import { IconButton } from '@mui/material';

const CustomerStats = ({ customerDetails }) => {
  const [showTotalRevenue, setShowTotalRevenue] = useState(true);
  return (
    <div className='customer-stats-container'>
      <div className='customer-total-revenue-container'>
        <img src='/images/customer-total-revenue.svg' />
        <div className='ms-4 w-100'>
          <div className='d-flex justify-content-between'>
            <p className='customer-stats-heading'>
              {showTotalRevenue ? 'Total Revenue' : 'Total Paid'}
            </p>
            <IconButton onClick={() => setShowTotalRevenue(!showTotalRevenue)}>
              <img src='/images/arrow-down.svg' />
            </IconButton>
          </div>
          <p className='customer-stats-content'>
            £
            {addCommas(
              Number(
                showTotalRevenue
                  ? customerDetails?.totalRevenue
                  : customerDetails?.totalPaid
              ).toFixed(2)
            )}
          </p>
        </div>
      </div>
      <div className='customer-total-due-container'>
        <img src='/images/customer-total-due.svg' />
        <div className='ms-4'>
          <p className='customer-stats-heading'>Amount Due</p>
          <p className='customer-stats-content'>
            £{addCommas(Number(customerDetails?.totalDue).toFixed(2))}
          </p>
        </div>
      </div>
      <div className='customer-total-invoices-container'>
        <img src='/images/customer-total-invoices.svg' />
        <div className='ms-4'>
          <p className='customer-stats-heading'>Total Invoices</p>
          <p className='customer-stats-content'>
            {customerDetails?.totalInvoices}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CustomerStats;
