import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCustomers } from '../../redux/actions/customer.actions';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import styled from '@emotion/styled';
import { Chip, IconButton, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import CustomButton from '../@generalComponents/CustomButton';
import CustomChip from '../@generalComponents/CustomChip';
import unicoAxios from '../../utils/unicoAxios';
import { modalConfirmation } from '../../redux/actions/modal.actions';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const Row = ({ row, deleteCustomer }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow
        sx={{
          '& > *': { borderBottom: 'unset' },
          height: !isMediumScreen ? 65 : 48,
        }}
      >
        <TableCell component='th' scope='row'>
          {row.customerName}
        </TableCell>
        {!isMediumScreen && (
          <React.Fragment>
            <TableCell>{row.businessName}</TableCell>
            <TableCell>{row.numOfInvoices}</TableCell>
          </React.Fragment>
        )}
        <TableCell>
          <CustomChip
            type='success'
            label={
              !row.latestInvoice
                ? '-'
                : `${moment(row?.latestInvoice).diff(moment(), 'days') * -1
                } DAYS`
            }
          />
        </TableCell>
        <TableCell
          sx={!isMediumScreen ? { paddingRight: '0 !important' } : null}
        >
          <div className='d-flex align-items-center'>
            {!isMediumScreen && (
              <IconButton
                aria-label='expand row'
                size='small'
                sx={{ display: 'flex', flexDirection: 'column' }}
                onClick={() => deleteCustomer(row?.id)}
              >
                <img src='/images/bin-icon.svg' width={20} height={20} />
                <p className='mb-0'>Delete</p>
              </IconButton>
            )}
            {!isMediumScreen ? (
              <CustomButton
                label='View & Edit'
                type='medium-purple'
                customStyle={{
                  width: '100%',
                  marginLeft: '1rem',
                }}
                onClick={() => navigate(`/customers/${row?.id}`)}
              />
            ) : (
              <IconButton
                className='ms-2'
                aria-label='expand row'
                size='small'
                sx={{ display: 'flex', flexDirection: 'column' }}
                onClick={() => setOpen(!open)}
              >
                <img src='/images/eye-icon.svg' width={20} height={20} />
              </IconButton>
            )}
          </div>
        </TableCell>
      </TableRow>
      {isMediumScreen && (
        <TableRow>
          <TableCell
            // style={{
            //   borderBottom: !open
            //     ? '1px solid rgba(224, 224, 224, 1) !important'
            //     : '0',
            //   ...(open && {
            // '& .MuiTableCell-root': {
            //   borderBottom: '0',
            // },
            //   }),
            // }}
            sx={
              open && {
                border: 'none',
              }
            }
            colSpan={6}
          >
            <Collapse in={open} timeout='auto' unmountOnExit>
              <div className='grid-row-collapse-container'>
                <span className='fw-400 me-2'>Business:</span>{' '}
                {row.businessName}
                <br />
                <span className='fw-400' style={{ marginRight: '10px' }}>
                  Invoices:
                </span>{' '}
                {row.numOfInvoices}
                <br />
                <div className='row mt-2'>
                  <div className='col-8'>
                    <CustomButton
                      label='Edit Customer'
                      type='medium-purple'
                      customStyle={{
                        width: '100%',
                      }}
                      onClick={() => navigate(`/customers/${row?.id}`)}
                    />
                  </div>
                  <div className='col-4 d-flex justify-content-center  align-items-center'>
                    <IconButton
                      aria-label='expand row'
                      sx={{ borderRadius: '8px' }}
                      onClick={() => deleteCustomer(row?.id)}
                    >
                      <div className='d-flex'>
                        <img
                          src='/images/bin-icon.svg'
                          width={20}
                          height={20}
                        />
                        <p className='mb-0 ms-1' style={{ fontSize: '14px' }}>
                          Delete
                        </p>
                      </div>
                    </IconButton>
                  </div>
                </div>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};

const AllCustomersGrid = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [rows, setRows] = useState([]);

  const currentUser = useSelector((state) => state.userReducer.currentUser);
  const customers = useSelector((state) => state.customerReducer.allCustomers);

  useEffect(() => {
    let tempRows = [];
    customers?.map((customer, index) => {
      tempRows.push({
        id: customer?.customerId,
        customerName: `${customer?.firstName} ${customer?.lastName}`,
        businessName: customer?.businessName,
        email: customer?.email,
        phone: customer?.phone,
        ...customer,
      });
    });
    setRows(tempRows);
  }, [customers]);

  const deleteCustomer = async (customerId) => {
    await unicoAxios({
      method: 'DELETE',
      url: `customers/delete/${customerId}/${currentUser?.business?.businessId}`,
      loadingMessage: 'Deleting customer...',
      successMessage: 'Customer deleted successfully!',
      onSuccess: () =>
        dispatch(getAllCustomers(currentUser?.business?.businessId)),
    });
  };

  const deleteCustomerConfirmation = (customerId) => {
    dispatch(
      modalConfirmation({
        isOpen: true,
        title: 'Delete Customer',
        desc: 'This will remove all data associated with this customer (including invoices).',
        footer: 'Are you sure you want to continue?',
        onConfirm: () => deleteCustomer(customerId),
      })
    );
  };

  return (
    <TableContainer>
      <Table aria-label='collapsible table'>
        <TableHead>
          <TableRow>
            <TableCell>Customer</TableCell>
            {!isMediumScreen && (
              <React.Fragment>
                <TableCell>Business Name</TableCell>
                <TableCell>Invoices</TableCell>
              </React.Fragment>
            )}
            <TableCell>Last Invoice</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row
              key={row.customerId}
              row={row}
              deleteCustomer={deleteCustomerConfirmation}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AllCustomersGrid;
