import unicoAxios from '../../utils/unicoAxios';

export const GET_SINGLE_INVOICE = 'GET_SINGLE_INVOICE';
export const CLEAR_SINGLE_INVOICE = 'CLEAR_SINGLE_INVOICE';

export const getSingleInvoice = (businessId, invoiceId) => {
  return async (dispatch) => {
    const { invoice } = await unicoAxios({
      method: 'GET',
      url: `invoices/${businessId}/${invoiceId}`,
    });
    dispatch({
      type: GET_SINGLE_INVOICE,
      payload: invoice,
    });
  };
};

export const clearSingleInvoice = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SINGLE_INVOICE,
      payload: null,
    });
  };
};
