import axios from 'axios';
import { toast } from 'react-hot-toast';

const DEV_URL = 'http://localhost:5000/api/';
const PROD_URL =
  'https://unico-invoicing-backend-9b83ae31cafa.herokuapp.com/api/';

const BASE_URL = process.env.REACT_APP_SERVER_URL;

//@params  method: string, url: string, body: object, noAuth: boolean
const unicoAxios = async ({
  method,
  url,
  body,
  noAuth,
  loadingMessage,
  successMessage,
  onSuccess,
}) => {
  let toastId;
  if (noAuth) {
    try {
      if (loadingMessage) toastId = toast.loading(loadingMessage);
      const response = await axios({
        method: method,
        url: url?.startsWith('https://') ? url : `${BASE_URL}${url}`,
        data: body,
      });
      if (toastId)
        toast.success(successMessage ? successMessage : 'Successful!', {
          id: toastId,
        });
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
      if (toastId)
        toast.error(
          error?.response?.data?.msg
            ? error?.response?.data?.msg
            : 'Something went wrong!',
          {
            id: toastId,
          }
        );
      return { error: true, errorData: error };
    }
  } else {
    const accessToken = localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken');
    if (accessToken) {
      try {
        if (loadingMessage) toastId = toast.loading(loadingMessage);
        const response = await axios({
          method: method,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          url: url?.startsWith('https://') ? url : `${BASE_URL}${url}`,
          data: body,
        });
        if (toastId)
          toast.success(successMessage ? successMessage : 'Successful!', {
            id: toastId,
          });
        if (onSuccess) onSuccess(response.data);
        return response.data;
      } catch (error) {
        console.error(error);
        if (error?.response?.status == 401 || error?.response?.status == 403) {
          localStorage.clear();
          window.location.reload();
          toast.error('Session expired!');
          return;
        }
        if (toastId)
          toast.error(
            error?.response?.data?.msg
              ? error?.response?.data?.msg
              : 'Something went wrong!',
            {
              id: toastId,
            }
          );
        return { error: true, errorData: error };
      }
    }
  }
};

export default unicoAxios;
