import { Avatar, Chip, Divider } from '@mui/material';
import React, { useEffect } from 'react';
import CustomButton from '../@generalComponents/CustomButton';
import {
  sidebarActiveNavLinks,
  sidebarInactiveNavLinks,
  sidebarNavLinks,
  sidebarSupportLinks,
} from '../../exports/commonExports';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Sidebar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const currentUser = useSelector((state) => state.userReducer.currentUser);

  return (
    <div className='sidebar'>
      <div className='bg-white business-card sidebar-margin'>
        <div className='d-flex justify-content-center'>
          <Avatar
            src={currentUser?.business?.logo}
            style={{ height: 58.5, width: 58.5 }}
          />
        </div>
        <div className='d-flex justify-content-center mt-2'>
          <h6>{currentUser?.business?.name}</h6>
        </div>
        <div className='d-flex justify-content-center mt-1'>
          <CustomButton
            label='Edit Business'
            type='medium'
            icon={
              <img
                src='/images/edit-icon.svg'
                alt='edit'
                style={{ width: '15px', height: '15px' }}
              />
            }
            onClick={() => navigate('/edit-business')}
          />
        </div>
      </div>
      <div className='sidebar-links'>
        {sidebarActiveNavLinks?.map((link, index) => {
          return (
            <div
              className='sidebar-link-item'
              key={index}
              style={
                pathname.includes(link.link)
                  ? {
                    background: 'rgba(255, 255, 255, 0.1)',
                  }
                  : null
              }
              onClick={() => navigate(link.link)}
            >
              <img src={link.icon} alt={link.name} />
              <p className='sidebar-link-name'>{link.name}</p>
            </div>
          );
        })}
      </div>
      <Divider sx={{ backgroundColor: '#E4EBEF', height: 2 }} />
      <div className='sidebar-links inactive'>
        {sidebarInactiveNavLinks?.map((link, index) => {
          return (
            <div className='sidebar-link-item' key={index}>
              <img src={link.icon} alt={link.name} />
              <div>
                <p className='sidebar-link-name'>{link.name}</p>
                <p className='sidebar-link-subtext'>Coming Soon</p>
              </div>
            </div>
          );
        })}
      </div>
      <Divider sx={{ backgroundColor: '#E4EBEF', height: 2 }} />
      <div className='pt-3'>
        {sidebarSupportLinks?.map((link, index) => {
          return (
            <div className='sidebar-link-item' key={index}>
              <img src={link.icon} alt={link.name} />
              <p className='sidebar-link-name'>{link.name}</p>
            </div>
          );
        })}
      </div>
      {/* <div className='d-flex justify-content-center  mt-3'>
        <CustomButton label='Change Plan' type='medium-light' />
      </div> */}
    </div>
  );
};

export default React.memo(Sidebar);
