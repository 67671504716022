import React from 'react';
import CustomButton from '../@generalComponents/CustomButton';
import { useNavigate } from 'react-router-dom';

const InvoicesCard = ({ mobile, totalInvoices }) => {
  const navigate = useNavigate();

  const openInvoiceCreate = () => {
    navigate('/invoices/create');
  };

  if (mobile)
    return (
      <div className='bg-white w-100 mb-3' style={{ padding: '10px 20px' }}>
        <div className='d-flex'>
          <img src='/images/invoice-icon-blue-dark.svg' width={35} />
          <div className='ms-3'>
            <p
              className='dashboard-card-title'
              style={{
                fontSize: '13px',
                fontWeight: 600
              }}
            >
              Invoices
            </p>
            <p
              className='dashboard-card-text'
              style={{
                fontSize: '22px',
                fontWeight: 700
              }}
            >
              {totalInvoices}
            </p>
          </div>
        </div>
      </div>
    );
  else
    return (
      <div className='bg-white w-100 mb-3' style={{ padding: 17 }}>
        <div className='d-flex align-items-center mb-2'>
          <img src='/images/invoice-icon-blue.svg' />
          <div className='ms-2'>
            <p className='dashboard-card-title'>Invoices</p>
            <p className='dashboard-card-text'>{totalInvoices}</p>
          </div>
        </div>
        <CustomButton
          type='medium-purple'
          label='CREATE'
          onClick={openInvoiceCreate}
        />
      </div>
    );
};

export default InvoicesCard;
