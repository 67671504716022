import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import SignIn from './views/SignIn';
import Register from './views/Register';
import CreateBusiness from './views/CreateBusiness';
import Dashboard from './views/Dashboard';
import CustomerPopulate from './views/customer/CustomerPopulate';
import AllCustomers from './views/customer/AllCustomers';
import InvoiceDetails from './views/invoice/InvoiceDetails';
import MainLayout from './layouts/MainLayout';
import CustomerDetails from './views/customer/CustomerDetails';
import AllInvoices from './views/invoice/AllInvoices';
import EditBusiness from './views/EditBusiness';
import AccountDetails from './views/AccountDetails';
import ForgotPassword from './views/ForgotPassword';
import ResetPassword from './views/ResetPassword';
import UpgradePlan from './views/UpgradePlan';

const RequireAuth = ({ children }) => {
  const accessToken = localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken');
  const user = JSON.parse(
    localStorage.getItem('currentUser') || sessionStorage.getItem('currentUser')
  );

  if (!accessToken || !user) return <Navigate to='/sign-in' />;
  else if (!user?.business)
    return <Navigate to={`/create-business/${user?.user?.userId}`} />;
  return children;
};

const OnlyForUnAuth = ({ children }) => {
  const accessToken = localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken');
  const user = JSON.parse(
    localStorage.getItem('currentUser') || sessionStorage.getItem('currentUser')
  );

  if (accessToken && user) return <Navigate to='/dashboard' />;
  return children;
};

const UnicoRoutes = () => {
  return (
    <Routes>
      <Route
        path='/'
        exact
        element={
          <RequireAuth>
            <Navigate to='/dashboard' />
          </RequireAuth>
        }
      />
      <Route
        path='/forgot-password'
        exact
        element={
          <OnlyForUnAuth>
            <ForgotPassword />
          </OnlyForUnAuth>
        }
      />
      <Route
        path='/reset-password/:resetPasswordToken'
        exact
        element={
          <ResetPassword />
        }
      />
      <Route
        path='/sign-in'
        exact
        element={
          <OnlyForUnAuth>
            <SignIn />
          </OnlyForUnAuth>
        }
      />
      <Route
        path='/register'
        exact
        element={
          <OnlyForUnAuth>
            <Register />
          </OnlyForUnAuth>
        }
      />
      <Route
        path='/create-business/:userId'
        exact
        element={<CreateBusiness />}
      />
      <Route
        path='/edit-business'
        exact
        element={
          <RequireAuth>
            <MainLayout>
              <EditBusiness />
            </MainLayout>
          </RequireAuth>
        }
      />
      <Route
        path='/account'
        exact
        element={
          <RequireAuth>
            <MainLayout>
              <AccountDetails />
            </MainLayout>
          </RequireAuth>
        }
      />
      <Route
        path='/upgrade-plan'
        exact
        element={
          <RequireAuth>
            <MainLayout>
              <UpgradePlan />
            </MainLayout>
          </RequireAuth>
        }
      />
      <Route
        path='/dashboard'
        exact
        element={
          <RequireAuth>
            <MainLayout>
              <Dashboard />
            </MainLayout>
          </RequireAuth>
        }
      />
      <Route
        path='/customers'
        exact
        element={
          <RequireAuth>
            <MainLayout>
              <AllCustomers />
            </MainLayout>
          </RequireAuth>
        }
      />
      <Route
        path='/customers/create'
        exact
        element={
          <RequireAuth>
            <MainLayout>
              <CustomerPopulate />
            </MainLayout>
          </RequireAuth>
        }
      />
      <Route
        path='/invoices'
        exact
        element={
          <RequireAuth>
            <MainLayout>
              <AllInvoices />
            </MainLayout>
          </RequireAuth>
        }
      />
      <Route
        path='/invoices/:invoiceId'
        exact
        element={
          <RequireAuth>
            <MainLayout>
              <InvoiceDetails />
            </MainLayout>
          </RequireAuth>
        }
      />
      <Route
        path='/invoices/create'
        exact
        element={
          <RequireAuth>
            <MainLayout>
              <InvoiceDetails />
            </MainLayout>
          </RequireAuth>
        }
      />
      <Route
        path='/customers/:customerId'
        exact
        element={
          <RequireAuth>
            <MainLayout>
              <CustomerDetails />
            </MainLayout>
          </RequireAuth>
        }
      />
      <Route
        path='/customers/update/:customerId'
        exact
        element={
          <RequireAuth>
            <MainLayout>
              <CustomerPopulate />
            </MainLayout>
          </RequireAuth>
        }
      />
    </Routes>
  );
};

export default UnicoRoutes;
