import { useState } from "react";
import CustomButton from "../components/@generalComponents/CustomButton";
import Input from "../components/@generalComponents/Input";
import unicoAxios from "../utils/unicoAxios";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [resLoading, setResLoading] = useState(false);

  const sendEmail = async () => {
    setResLoading(true);
    await unicoAxios({
      method: 'POST',
      url: `users/forgot-password`,
      body: {
        email: email,
      },
      noAuth: true,
      loadingMessage: 'Sending email...',
      successMessage: 'Successful!',
      onSuccess: () => navigate('/sign-in'),
    });
    setResLoading(false);
  }

  return (
    <div
      className="container d-flex align-items-center justify-content-center"
      style={{ height: '100vh' }}
    >
      <div className="row d-flex justify-content-center w-100">
        <div className="col-md-6">
          <div className='invoice-card'>
            <div className="d-flex justify-content-center mb-5">
              <img
                src='/images/unico-logo-large.svg'
                width='50%'
              // height='40px'
              />
            </div>
            <h1 className="heading-secondary mb-0">
              Forgot your password?
            </h1>
            <p className='subtitle-secondary mb-3'>
              No worries! Enter the email you used to sign up
              for your Unico account. We'll  email you instructions
              on how to reset your password.
            </p>
            <Input
              type='text'
              name='email'
              startIcon='/images/email-icon.svg'
              placeholder='Email'
              onChange={(e) => setEmail(e?.target?.value)}
            />
            <CustomButton
              type='medium'
              label='Send Email'
              customStyle={{
                height: '45px',
                fontSize: '20px',
                marginTop: '25px'
              }}
              onClick={sendEmail}
              disabled={resLoading}
            />
          </div>
        </div>
      </div>

    </div>
  );
};

export default ForgotPassword;