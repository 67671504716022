import { useState } from "react";
import CustomButton from "../components/@generalComponents/CustomButton";
import Input from "../components/@generalComponents/Input";
import unicoAxios from "../utils/unicoAxios";
import { useNavigate, useParams } from "react-router-dom";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { resetPasswordToken } = useParams();

  const [password, setPassword] = useState('');
  const [resLoading, setResLoading] = useState(false);

  const logOut = () => {
    localStorage.clear();
    navigate('/sign-in');
    window.location.reload();
  };

  const resetPassword = async () => {
    setResLoading(true);
    await unicoAxios({
      method: 'POST',
      url: `users/reset-password`,
      body: {
        newPassword: password,
        resetPasswordToken
      },
      noAuth: true,
      loadingMessage: 'Resetting password...',
      successMessage: 'Password reset! Please log in.',
      onSuccess: () => setTimeout(logOut, 1500),
    });
    setResLoading(false);
  }

  return (
    <div
      className="container d-flex align-items-center justify-content-center"
      style={{ height: '100vh' }}
    >
      <div className="row d-flex justify-content-center w-100">
        <div className="col-md-6">
          <div className='invoice-card'>
            <div className="d-flex justify-content-center mb-5">
              <img
                src='/images/unico-logo-large.svg'
                width='50%'
              />
            </div>
            <h1 className="heading-secondary mb-0">
              Reset your password.
            </h1>
            <p className='subtitle-secondary mb-3'>
              Enter your new password to reset it. On a
              successful reset, you will be asked to login with
              your new password.
            </p>
            <Input
              type='password'
              name='password'
              startIcon='/images/password-key.svg'
              placeholder='**********'
              onChange={(e) => setPassword(e?.target?.value)}
            />
            <CustomButton
              type='medium'
              label='Reset Password'
              customStyle={{
                height: '45px',
                fontSize: '20px',
                marginTop: '25px'
              }}
              onClick={resetPassword}
              disabled={resLoading}
            />
          </div>
        </div>
      </div>

    </div>
  );
};

export default ResetPassword;