import * as Actions from '../actions/modal.actions';
const initialState = {
  modalConfirmation: { isOpen: false },
  modalAddPayment: { isOpen: false },
  modalNoCustomers: { isOpen: false },
  modalUpgradeSuccess: { isOpen: false },
};

export const modalReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Actions.MODAL_CONFIRMATION:
      return { ...initialState, modalConfirmation: payload };
    case Actions.MODAL_ADD_PAYMENT:
      return { ...initialState, modalAddPayment: payload };
    case Actions.MODAL_NO_CUSTOMERS:
      return { ...initialState, modalNoCustomers: payload };
    case Actions.MODAL_UPGRADE_SUCCESS:
      return { ...initialState, modalUpgradeSuccess: payload };
    default:
      return state;
  }
};
