import React from 'react';
import CustomButton from '../@generalComponents/CustomButton';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const CustomersCard = ({ mobile }) => {
  const navigate = useNavigate();

  const customers = useSelector((state) => state.customerReducer.allCustomers);

  const openInvoiceCreate = () => {
    navigate('/customers/create');
  };

  if (mobile)
    return (
      <div className='bg-white w-100 mb-3' style={{ padding: '10px 20px' }}>
        <div className='d-flex'>
          <img src='/images/customer-icon-blue-dark.svg' width={35} />
          <div className='ms-3'>
            <p
              className='dashboard-card-title'
              style={{
                fontSize: '13px',
                fontWeight: 600
              }}
            >
              Customers
            </p>
            <p
              className='dashboard-card-text'
              style={{
                fontSize: '22px',
                fontWeight: 700
              }}
            >
              {customers?.length}
            </p>
          </div>
        </div>
      </div>
    );
  else
    return (
      <div className='bg-white w-100' style={{ padding: 17 }}>
        <div className='d-flex align-items-center mb-2'>
          <img src='/images/customer-icon-blue.svg' />
          <div className='ms-3'>
            <p className='dashboard-card-title'>Customers</p>
            <p className='dashboard-card-text'>{customers?.length}</p>
          </div>
        </div>
        <CustomButton
          type='medium-purple'
          label='CREATE'
          onClick={openInvoiceCreate}
        />
      </div>
    );
};

export default CustomersCard;
