import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import Input from '../../components/@generalComponents/Input';
import { Divider } from '@mui/material';
import CustomButton from '../../components/@generalComponents/CustomButton';
import unicoAxios from '../../utils/unicoAxios';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import Loader from '../../components/@generalComponents/Loader';
import { countryList } from '../../exports/commonExports';
import { getAllCustomers } from '../../redux/actions/customer.actions';

const CustomerPopulate = () => {
  const dispatch = useDispatch();
  const { customerId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [formState, setFormState] = useState({
    country: 'GB',
    title: 'Mr'
  });

  const currentUser = useSelector((state) => state.userReducer.currentUser);

  useEffect(() => {
    if (!customerId) return;
    getCustomerDetails();
  }, [customerId]);

  const changeHandler = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const getCustomerDetails = async () => {
    const { customer } = await unicoAxios({
      method: 'GET',
      url: `customers/${customerId}`,
    });
    setFormState({
      customerId: customer?.customerId,
      title: customer?.title || 'Other',
      firstName: customer?.firstName,
      lastName: customer?.lastName,
      businessName: customer?.businessName,
      email: customer?.email,
      phone: customer?.phone,
      address: customer?.address?.address,
      city: customer?.address?.city,
      region: customer?.address?.region,
      postcode: customer?.address?.postcode,
      country: customer?.address?.country,
      notes: customer?.notes,
    });
  };

  const handleCreate = async () => {
    await unicoAxios({
      method: 'POST',
      url: `customers/create/${currentUser?.business?.businessId}`,
      body: {
        ...formState,
        ...(formState?.title !== 'Other' && { title: formState?.title }),
      },
      loadingMessage: 'Creating Customer...',
      successMessage: 'Customer Created Successfully!',
      onSuccess: () => {
        dispatch(getAllCustomers(currentUser?.business?.businessId));
        setTimeout(checkForRedirection, 1500);
      }
    });
  };

  const handleUpdate = async () => {
    await unicoAxios({
      method: 'PATCH',
      url: `customers/${formState?.customerId}`,
      body: {
        businessId: currentUser?.business?.businessId,
        ...formState,
        ...(formState?.title !== 'Other' && { title: formState?.title }),
      },
      loadingMessage: 'Updating Customer...',
      successMessage: 'Customer Updated Successfully!',
      onSuccess: () => {
        dispatch(getAllCustomers(currentUser?.business?.businessId));
        setTimeout(checkForRedirection, 1500);
      }
    });
  };

  const checkForRedirection = () => {
    const redirectUrl = searchParams.get('redirectUrl');
    if (!redirectUrl)
      navigate('/customers');
    else navigate(redirectUrl);
  }

  const handleSubmit = () => {
    if (customerId) handleUpdate();
    else handleCreate();
  };

  return (
    <Loader renderChildren={customerId ? formState?.customerId : true}>
      <h1 className='heading-primary'>
        {customerId ? 'Update Customer' : 'New Customer'}
      </h1>
      <div className='row white-card'>
        <div className='d-flex align-items-center mb-2'>
          <img
            src='/images/avatar-icon.svg'
            style={{ width: '30px', height: '25px', margin: '0 10px 5px 0' }}
          />
          <h2 className='heading-secondary m-0'>Personal Details</h2>
        </div>
        <div className='col-md-2 mb-4'>
          <Input
            thin
            type='select'
            value={formState?.title}
            name='title'
            placeholder='Title'
            options={[
              {
                label: 'Mr.',
                value: 'Mr',
              },
              {
                label: 'Mrs.',
                value: 'Mrs',
              },
              {
                label: 'Ms.',
                value: 'Ms',
              },
              {
                label: 'Other',
                value: 'Other',
              },
            ]}
            onChange={changeHandler}
          />
        </div>
        <div className='col-md-5 mb-4'>
          <Input
            thin
            type='text'
            name='firstName'
            placeholder='First Name'
            value={formState?.firstName}
            onChange={changeHandler}
          />
        </div>
        <div className='col-md-5 mb-4'>
          <Input
            thin
            type='text'
            name='lastName'
            placeholder='Last Name'
            value={formState?.lastName}
            onChange={changeHandler}
          />
        </div>
        <div className='col-12 mb-4'>
          <Input
            thin
            type='text'
            name='businessName'
            placeholder='Business Name'
            value={formState?.businessName}
            onChange={changeHandler}
          />
        </div>
        <div className='col-md-6 mb-4'>
          <Input
            thin
            type='text'
            name='email'
            placeholder='Email'
            value={formState?.email}
            onChange={changeHandler}
          />
        </div>
        <div className='col-md-6 mb-4'>
          <Input
            thin
            type='text'
            name='phone'
            placeholder='Phone'
            value={formState?.phone}
            onChange={changeHandler}
          />
        </div>
        <Divider
          sx={{
            border: '1px solid #D9D9D9',
            marginTop: '20px',
            // marginBottom: '20px',
          }}
        />
        <div className='col-md-6 mt-4'>
          <div className='d-flex align-items-center mb-2'>
            <img
              src='/images/address-icon.svg'
              style={{
                width: '30px',
                height: '25px',
                margin: '0 10px 5px 0',
              }}
            />
            <h2 className='heading-secondary mb-0'>Address</h2>
          </div>
          <div className='mb-4'>
            <Input
              thin
              type='text'
              name='address'
              placeholder='House No. & Street'
              value={formState?.address}
              onChange={changeHandler}
            />
          </div>
          <div className='mb-4'>
            <Input
              thin
              type='text'
              name='city'
              placeholder='Town/City'
              value={formState?.city}
              onChange={changeHandler}
            />
          </div>
          <div className='mb-4'>
            <Input
              thin
              type='text'
              name='region'
              placeholder='Region/Country'
              value={formState?.region}
              onChange={changeHandler}
            />
          </div>
          <div className='mb-4'>
            <Input
              thin
              type='text'
              name='postcode'
              placeholder='Postcode'
              value={formState?.postcode}
              onChange={changeHandler}
            />
          </div>
          <div>
            <Input
              type='select'
              thin
              value={formState?.country}
              name='country'
              placeholder='Country'
              options={countryList.map((country) => {
                return {
                  label: country.name,
                  value: country.code,
                };
              })}
              onChange={changeHandler}
            />
          </div>
        </div>
        <div className='col-md-6 mt-4'>
          <div className='d-flex align-items-center mb-2'>
            <img
              src='/images/notes-icon.svg'
              style={{
                width: '30px',
                height: '25px',
                margin: '0 10px 5px 0',
              }}
            />
            <h2 className='heading-secondary mb-0'>Notes</h2>
          </div>
          <div className='col-12 mb-4'>
            <Input
              type='textarea'
              name='notes'
              value={formState?.notes}
              onChange={changeHandler}
              rows={8}
              innerInputSx={{ height: '232px' }}
            />
          </div>
          <CustomButton
            type='medium'
            label='Save'
            onClick={handleSubmit}
            customStyle={{
              height: '43px',
              padding: '3px 10px',
              fontSize: '20px',
              fontWeight: '600',
            }}
          />
        </div>
      </div>
    </Loader>
  );
};

export default CustomerPopulate;
