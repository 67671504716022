import unicoAxios from '../../utils/unicoAxios';

export const GET_ALL_CUSTOMERS = 'GET_ALL_CUSTOMERS';

export const getAllCustomers = (businessId) => {
  return async (dispatch) => {
    const { customers } = await unicoAxios({
      method: 'GET',
      url: `customers/all/${businessId}`,
    });
    dispatch({
      type: GET_ALL_CUSTOMERS,
      payload: customers,
    });
  };
};
