import unicoAxios from '../../utils/unicoAxios';

export const GET_CURRENT_USER = 'GET_CURRENT_USER';

export const getCurrentUser = (onComplete) => {
  return async (dispatch) => {
    const { user, business } = await unicoAxios({
      method: 'GET',
      url: 'users/me',
    });
    if (user || business)
      localStorage.setItem('currentUser', JSON.stringify({ user, business }));
    dispatch({
      type: GET_CURRENT_USER,
      payload: { user, business },
    });
    if (onComplete) onComplete();
  };
};
