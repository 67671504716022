import * as Actions from '../actions/customer.actions';

const initialState = {
  allCustomers: null,
};

export const customerReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Actions.GET_ALL_CUSTOMERS:
      return (state = {
        ...state,
        allCustomers: payload,
      });
    default:
      return state;
  }
};
