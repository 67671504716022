import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import CustomButton from '../../components/@generalComponents/CustomButton';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import AllCustomersGrid from '../../components/grids/AllCustomersGrid';
import CustomerDropdown from '../../components/@generalComponents/CustomerDropdown';
import Input from '../../components/@generalComponents/Input';
import CustomerDetails from '../../components/InvoiceDetails/CustomerDetails';
import { useDispatch, useSelector } from 'react-redux';
import getRandomString from '../../utils/getRandomString';
import InvoiceItems from '../../components/InvoiceDetails/InvoiceItems';
import { Divider, IconButton } from '@mui/material';
import Loader from '../../components/@generalComponents/Loader';
import unicoAxios from '../../utils/unicoAxios';
import moment from 'moment/moment';
import InvoiceAdditionalDetails from '../../components/InvoiceDetails/InvoiceAdditionalDetails';
import InvoiceControls from '../../components/InvoiceDetails/InvoiceControls';
import { clearSingleInvoice, getSingleInvoice } from '../../redux/actions/invoice.actions';
import InvoiceTotals from '../../components/InvoiceDetails/InvoiceTotals';
import { modalNoCustomers, modalUpgradeSuccess } from '../../redux/actions/modal.actions';
import { invoiceTerms } from '../../exports/commonExports';

const InvoiceDetails = () => {
  const { invoiceId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [businessVatRegistered, setBusinessVatRegistered] = useState(false);
  const [includeVat, setIncludeVat] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState({
    status: 0,
    subtotal: 0.0,
    vat: 0.0,
    total: 0.0,
    dueDate: moment().add(30, 'days').toDate()
  });
  const [invoiceItems, setInvoiceItems] = useState([
    {
      key: getRandomString(20),
      name: '',
      quantity: '',
      price: '',
      vat: 20,
    },
  ]);
  const [itemTotals, setItemTotals] = useState([]);

  console.log(itemTotals)

  const currentUser = useSelector((state) => state.userReducer.currentUser);
  const invoice = useSelector((state) => state.invoiceReducer.singleInvoice);
  const customers = useSelector((state) => state.customerReducer.allCustomers);

  useEffect(() => {
    return () => dispatch(clearSingleInvoice());
  }, []);

  //restoring saved draft from local storage
  useEffect(() => {
    if (!localStorage.getItem('invoiceDraft')) return;
    const invoiceDraft = JSON.parse(localStorage.getItem('invoiceDraft'));
    setSelectedCustomer(invoiceDraft?.selectedCustomer);
    setBusinessVatRegistered(invoiceDraft?.businessVatRegistered);
    setIncludeVat(invoiceDraft?.includeVat);
    setInvoiceDetails(invoiceDraft?.invoiceDetails);
    setInvoiceItems(invoiceDraft?.invoiceItems);
    setItemTotals(invoiceDraft?.itemTotals);
    localStorage.removeItem('invoiceDraft');
  }, []);

  //show upgrade success modal is returning after upgrade
  useEffect(() => {
    const upgradeSuccess = searchParams.get('upgradeSuccess');
    if (!upgradeSuccess) return;
    dispatch(modalUpgradeSuccess({ isOpen: true }));
  }, [searchParams])

  //show no customers modal if no customers
  useEffect(() => {
    if (!customers || customers?.length > 0) return;
    dispatch(modalNoCustomers({ isOpen: true }));
  }, [customers]);

  useEffect(() => {
    if (currentUser?.business) {
      if (!invoiceId)
        setBusinessVatRegistered(currentUser?.business?.isVatRegistered);
      else
        dispatch(
          getSingleInvoice(currentUser?.business?.businessId, invoiceId)
        );
    }
  }, [currentUser, invoiceId]);

  useEffect(() => {
    if (localStorage.getItem('invoiceDraft')) return;
    calculateTotals();
  }, [includeVat]);

  useEffect(() => {
    if (!invoice) return;
    setBusinessVatRegistered(invoice?.isVATRegistered);
    setIncludeVat(invoice?.isVATInclusive);
    setSelectedCustomer(invoice?.customer);
    const terms = moment(invoice?.dueDate).diff(moment(invoice?.invoiceDate), 'days');
    const foundTerm = invoiceTerms.find(term => term.value == terms);
    setInvoiceDetails({
      invoiceId: invoice?.invoiceId,
      invoiceNumber: invoice?.invoiceNumber,
      invoiceDate: new Date(invoice?.invoiceDate),
      dueDate: new Date(invoice?.dueDate),
      status: invoice?.status,
      subtotal: invoice?.subtotal,
      vat: invoice?.vatAmount,
      total: invoice?.total,
      discountType: invoice?.discountType,
      discountAmount: invoice?.discountAmount,
      discountValue: invoice?.discountValue,
      notes: invoice?.notes,
      emailTo: invoice?.customer?.email,
      paymentDate: invoice?.paymentDate,
      sentVia: invoice?.sentVia,
      latestEmailSentOn: invoice?.latestEmailSentOn,
      privateNotes: invoice?.privateNotes,
      terms: foundTerm ? terms : 'CUSTOM'
    });
    let tempInvoiceItems = [];
    let tempInvoiceTotals = [];
    for (let item of invoice?.items) {
      const itemKey = getRandomString(20);
      tempInvoiceItems.push({
        key: itemKey,
        name: item?.name,
        quantity: item?.quantity,
        price: item?.price,
        vat: item?.vatPercentage,
      });
      tempInvoiceTotals.push({
        key: itemKey,
        total: item?.itemTotal,
      });
    }
    setInvoiceItems(tempInvoiceItems);
    setItemTotals(tempInvoiceTotals);
  }, [invoice]);

  //makes the calculations for subtotal, vat and total
  const calculateTotals = () => {
    const clonedInvoiceItems = JSON.parse(JSON.stringify(invoiceItems));
    let subtotal = 0;
    let vat = 0;
    let total = 0;
    const tempItemTotals = [];
    for (let item of clonedInvoiceItems) {
      const itemTotal = Number(
        parseFloat(item.price === '' ? 0 : item?.price) *
        parseFloat(item?.quantity === '' ? 0 : item?.quantity)
      ).toFixed(2);
      if (businessVatRegistered) {
        if (!includeVat) {
          subtotal += parseFloat(itemTotal);
          vat += (item?.vat / 100) * itemTotal;
        } else {
          subtotal += parseFloat(itemTotal) / (1 + item?.vat / 100);
          vat +=
            parseFloat(itemTotal) -
            parseFloat(itemTotal) / (1 + item?.vat / 100);
        }
      } else subtotal += parseFloat(itemTotal);
      tempItemTotals.push({
        key: item?.key,
        total: itemTotal,
      });
    }
    if (businessVatRegistered)
      total = parseFloat(subtotal) + parseFloat(vat) - getDiscountAmount();
    else total = parseFloat(subtotal) - getDiscountAmount();
    setItemTotals(tempItemTotals);
    setInvoiceDetails({
      ...invoiceDetails,
      subtotal: subtotal,
      vat: vat,
      total: total,
    });
  };

  //returns the discount amount according to type
  const getDiscountAmount = () => {
    if (!canApplyDiscount()) return 0;
    if (!invoiceDetails?.discountValue || !invoiceDetails?.discountType)
      return 0;
    if (invoiceDetails?.discountType === 'UNIT') {
      return parseFloat(invoiceDetails?.discountValue);
    } else {
      return (
        (parseFloat(invoiceDetails?.discountValue) / 100) *
        parseFloat(invoiceDetails?.subtotal)
      );
    }
  };

  const canApplyDiscount = () => {
    if (invoiceItems?.length === 0) return true;
    const vatValue = invoiceItems[0]?.vat;
    for (let i = 1; i < invoiceItems.length; i++) {
      if (invoiceItems[i].vat !== vatValue) {
        return false;
      }
    }
    return true;
  };

  //creates the invoice
  const createInvoice = async () => {
    const applyDiscount = canApplyDiscount();
    const { errorData } = await unicoAxios({
      method: 'POST',
      url: `invoices/create/${currentUser?.business?.businessId}`,
      body: {
        customerId: selectedCustomer?.customerId,
        invoiceDate: moment(invoiceDetails?.invoiceDate).format('YYYY/MM/DD'),
        dueDate: moment(invoiceDetails?.dueDate).format('YYYY/MM/DD'),
        items: invoiceItems.map((item) => {
          return {
            name: item.name,
            quantity: parseFloat(item?.quantity),
            price: parseFloat(item?.price),
            ...(businessVatRegistered && {
              vatPercentage: parseFloat(item?.vat),
            }),
            itemTotal: parseFloat(
              itemTotals?.find((itemVal) => itemVal?.key === item?.key)?.total
            ),
          };
        }),
        isVATRegistered: businessVatRegistered,
        isVATInclusive: includeVat,
        vatAmount: parseFloat(invoiceDetails?.vat),
        ...(applyDiscount && {
          discountType: invoiceDetails?.discountType,
          discountValue: parseFloat(invoiceDetails?.discountValue),
          discountAmount: getDiscountAmount(),
        }),
        subtotal: parseFloat(invoiceDetails?.subtotal),
        total: parseFloat(invoiceDetails?.total),
        notes: invoiceDetails?.notes,
      },
      loadingMessage: 'Creating invoice...',
      successMessage: 'Invoice created!',
      onSuccess: (data) => {
        navigate(`/invoices/${data?.invoice?.invoiceId}`);
      },
    });
    if (errorData?.response?.status == 402) {
      localStorage.setItem('invoiceDraft', JSON.stringify({
        selectedCustomer,
        businessVatRegistered,
        includeVat,
        invoiceDetails,
        invoiceItems,
        itemTotals
      }));
      setTimeout(
        () => navigate('/upgrade-plan'),
        1000
      );
    }
  };

  //updated the invoice
  const updateInvoice = async () => {
    const applyDiscount = canApplyDiscount();
    await unicoAxios({
      method: 'PATCH',
      url: `invoices/${currentUser?.business?.businessId}/${invoiceId}`,
      body: {
        customerId: selectedCustomer?.customerId,
        invoiceDate: moment(invoiceDetails?.invoiceDate).format('YYYY/MM/DD'),
        dueDate: moment(invoiceDetails?.dueDate).format('YYYY/MM/DD'),
        items: invoiceItems.map((item) => {
          return {
            name: item.name,
            quantity: parseFloat(item?.quantity),
            price: parseFloat(item?.price),
            ...(businessVatRegistered && {
              vatPercentage: parseFloat(item?.vat),
            }),
            itemTotal: parseFloat(
              itemTotals?.find((itemVal) => itemVal?.key === item?.key)?.total
            ),
          };
        }),
        isVATRegistered: businessVatRegistered,
        isVATInclusive: includeVat,
        vatAmount: parseFloat(invoiceDetails?.vat),
        ...(applyDiscount && {
          discountType: invoiceDetails?.discountType,
          discountValue: parseFloat(invoiceDetails?.discountValue),
          discountAmount: getDiscountAmount(),
        }),
        subtotal: parseFloat(invoiceDetails?.subtotal),
        total: parseFloat(invoiceDetails?.total),
        notes: invoiceDetails?.notes,
      },
      loadingMessage: 'Updating invoice...',
      successMessage: 'Invoice updated!',
    });
  };

  const createInvoiceAndEmail = async () => {
    const applyDiscount = canApplyDiscount();
    const { invoice, errorData } = await unicoAxios({
      method: 'POST',
      url: `invoices/create/${currentUser?.business?.businessId}`,
      body: {
        customerId: selectedCustomer?.customerId,
        invoiceDate: moment(invoiceDetails?.invoiceDate).format('YYYY/MM/DD'),
        dueDate: moment(invoiceDetails?.dueDate).format('YYYY/MM/DD'),
        items: invoiceItems.map((item) => {
          return {
            name: item.name,
            quantity: parseFloat(item?.quantity),
            price: parseFloat(item?.price),
            ...(businessVatRegistered && {
              vatPercentage: parseFloat(item?.vat),
            }),
            itemTotal: parseFloat(
              itemTotals?.find((itemVal) => itemVal?.key === item?.key)?.total
            ),
          };
        }),
        isVATRegistered: businessVatRegistered,
        isVATInclusive: includeVat,
        vatAmount: parseFloat(invoiceDetails?.vat),
        ...(applyDiscount && {
          discountType: invoiceDetails?.discountType,
          discountValue: parseFloat(invoiceDetails?.discountValue),
          discountAmount: getDiscountAmount(),
        }),
        subtotal: parseFloat(invoiceDetails?.subtotal),
        total: parseFloat(invoiceDetails?.total),
        notes: invoiceDetails?.notes,
      },
      loadingMessage: 'Creating nvoice...',
      successMessage: 'Invoice created!',
    });
    if (errorData?.response?.status == 402) {
      localStorage.setItem('invoiceDraft', JSON.stringify({
        selectedCustomer,
        businessVatRegistered,
        includeVat,
        invoiceDetails,
        invoiceItems,
        itemTotals
      }));
      setTimeout(
        () => navigate('/upgrade-plan'),
        1000
      );
    } else {
      await unicoAxios({
        method: 'POST',
        url: `invoices/email/${currentUser?.business?.businessId}/${invoice?.invoiceId}`,
        body: {
          email: invoiceDetails?.emailTo,
          text: invoiceDetails?.emailMessage,
        },
        loadingMessage: 'Sending Invoice by email...',
        successMessage: 'Invoice sent successfully. It can take up to 5 minutes for this email to reach their inbox.',
        onSuccess: (data) => {
          navigate(`/invoices/${invoice?.invoiceId}`);
        },
      });
    }
  };

  return (
    <Loader renderChildren={invoiceId ? invoiceDetails?.invoiceId : true}>
      <div className='row' style={{ position: 'relative' }}>
        <div className='col-md-8 invoice-card'>
          <div className='row d-flex justify-content-between align-items-start'>
            <div className='col-md-6'>
              <h1
                className='heading-primary'
                style={{ marginTop: -15, marginBottom: 0 }}
              >
                {invoiceId ? 'Edit Invoice' : 'Create Invoice'}
              </h1>
            </div>
            <div className='col-md-6'>
              <CustomerDropdown
                label='Customer'
                value={selectedCustomer?.customerId}
                onSelect={(cust) => {
                  setSelectedCustomer(cust);
                  setInvoiceDetails({
                    ...invoiceDetails,
                    emailTo: cust?.email,
                  });
                }}
              />
              <p
                className='color-secondary text-end hover-on mt-1'
                onClick={() => navigate('/customers/create')}
                style={{ fontSize: 14 }}
              >
                Add new customer?
              </p>
            </div>
          </div>
          <CustomerDetails
            selectedCustomer={selectedCustomer}
            invoiceDetails={invoiceDetails}
            setInvoiceDetails={setInvoiceDetails}
          />
          <InvoiceItems
            businessVatRegistered={businessVatRegistered}
            invoiceItems={invoiceItems}
            setInvoiceItems={setInvoiceItems}
            itemTotals={itemTotals}
            calculateTotals={calculateTotals}
          />
          <Divider
            sx={{
              backgroundColor: '#d9d9d9',
              height: 2,
              marginTop: 10,
              marginBottom: 2,
            }}
          />
          <InvoiceTotals
            invoiceDetails={invoiceDetails}
            setInvoiceDetails={setInvoiceDetails}
            includeVat={includeVat}
            setIncludeVat={setIncludeVat}
            businessVatRegistered={businessVatRegistered}
            canApplyDiscount={canApplyDiscount}
            calculateTotals={calculateTotals}
          />
        </div>
        <div className='col-md-4 invoice-controls'>
          <InvoiceAdditionalDetails
            invoiceDetails={invoiceDetails}
          />
          <InvoiceControls
            invoiceDetails={invoiceDetails}
            setInvoiceDetails={setInvoiceDetails}
            createInvoice={createInvoice}
            createInvoiceAndEmail={createInvoiceAndEmail}
            updateInvoice={updateInvoice}
          />
        </div>
      </div>
    </Loader>
  );
};

export default InvoiceDetails;
