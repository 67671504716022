import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Divider, IconButton, TableCell, TableRow } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import CustomButton from '../../@generalComponents/CustomButton';
import CustomChip from '../../@generalComponents/CustomChip';
import unicoAxios from '../../../utils/unicoAxios';
import {
  modalAddPayment,
  modalConfirmation,
} from '../../../redux/actions/modal.actions';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { invoiceStatuses } from '../../../exports/commonExports';

const RecentInvoicesRow = ({ row }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = useState(false);

  const currentUser = useSelector((state) => state.userReducer.currentUser);

  const editInvoice = () => {
    navigate(`/invoices/${row?.invoiceId}`);
  };

  const openAddPaymentModal = () => {
    dispatch(
      modalAddPayment({
        isOpen: true,
        invoiceId: row?.invoiceId,
        invoiceNumber: row?.invoiceNumber,
      })
    );
  };

  const removePaymentOnInvoice = async () => {
    await unicoAxios({
      method: 'PATCH',
      url: `invoices/${currentUser?.business?.businessId}/${row?.invoiceId}`,
      body: {
        isPaid: false,
        status: invoiceStatuses.SENT,
      },
      loadingMessage: 'Removing payment on invoice...',
      successMessage: 'Payment removed successfully!',
    });
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{
          '& > *': { borderBottom: 'unset' },
          height: !isMediumScreen ? 65 : 48,
        }}
      >
        {!isMediumScreen && (
          <React.Fragment>
            <TableCell>
              {moment(row.invoiceDate).format('DD/MM/YYYY')}
            </TableCell>
          </React.Fragment>
        )}
        <TableCell>
          {row?.customer?.firstName + ' ' + row?.customer?.lastName}
        </TableCell>
        <TableCell>£{row.total}</TableCell>
        <TableCell>
          <CustomChip
            type={
              row.status == 0
                ? 'warning'
                : row.status == 1
                  ? 'danger'
                  : 'success'
            }
            label={
              row.status == 0 ? 'Draft' : row.status == 1 ? 'SENT' : 'PAID'
            }
            width='80%'
          />
        </TableCell>
        <TableCell
          sx={!isMediumScreen ? { paddingRight: '0 !important' } : null}
        >
          <div className='d-flex align-items-center'>
            {!isMediumScreen ? (
              <React.Fragment>
                <IconButton
                  aria-label='expand row'
                  size='medium'
                  sx={{
                    borderRadius: '4px',
                  }}
                  onClick={editInvoice}
                >
                  <img
                    src='/images/edit-icon-dark.svg'
                    width={20}
                    height={20}
                  />
                  <p className='mb-0'>Edit</p>
                </IconButton>
                <img
                  className='hover-on'
                  src={
                    !row?.isPaid
                      ? '/images/invoice-paid.svg'
                      : '/images/invoice-unpaid.svg'
                  }
                  onClick={
                    !row?.isPaid ? openAddPaymentModal : removePaymentOnInvoice
                  }
                  width={50}
                  height={50}
                />
              </React.Fragment>
            ) : (
              <IconButton
                className='ms-2'
                aria-label='expand row'
                size='small'
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  borderRadius: '4px',
                }}
                onClick={() => setOpen(!open)}
              >
                <img src='/images/eye-icon.svg' width={20} height={20} />
              </IconButton>
            )}
          </div>
        </TableCell>
      </TableRow>
      {isMediumScreen && (
        <TableRow>
          <TableCell
            sx={
              open && {
                border: 'none',
              }
            }
            colSpan={6}
          >
            <Collapse in={open} timeout='auto' unmountOnExit>
              <div className='grid-row-collapse-container'>
                <p style={{ fontSize: '16px', fontWeight: 600 }}>
                  #{row?.invoiceNumber?.toString().padStart(3, '0')}
                </p>
                <div>
                  <span className='fw-400 me-2'>Business:</span>
                  {row?.customer?.businessName}
                </div>
                <span className='fw-400 me-2'>Date:</span>
                {moment(row.invoiceDate).format('MM/DD/YYYY')}
                <br />
                <div className='d-flex justify-content-start mt-3 mb-2'>
                  <div>
                    <IconButton
                      aria-label='expand row'
                      size='medium'
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '4px',
                        height: '100%',
                      }}
                      onClick={editInvoice}
                    >
                      <div className='d-flex'>
                        <img
                          src='/images/edit-icon-dark.svg'
                          width={20}
                          height={20}
                        />
                        <p
                          className='mb-0'
                          style={{
                            fontWeight: 400,
                            fontSize: '14px',
                          }}
                        >
                          Edit
                        </p>
                      </div>
                    </IconButton>
                  </div>
                </div>
                {row?.isPaid ? (
                  <CustomButton
                    type='medium-purple'
                    label='Remove Payment'
                    onClick={removePaymentOnInvoice}
                  />
                ) : (
                  <CustomButton
                    type='medium-purple'
                    label='Add Payment'
                    onClick={openAddPaymentModal}
                  />
                )}
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};

export default RecentInvoicesRow;
