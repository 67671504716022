import * as Actions from '../actions/invoice.actions';

const initialState = {
  singleInvoice: null,
};

export const invoiceReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Actions.GET_SINGLE_INVOICE:
      return (state = {
        ...state,
        singleInvoice: payload,
      });
    case Actions.CLEAR_SINGLE_INVOICE:
      return (state = {
        ...state,
        singleInvoice: payload,
      });
    default:
      return state;
  }
};
