import { Chip, Divider, Menu, MenuItem } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomButton from "../@generalComponents/CustomButton";

const AccountMenu = ({
  anchorEl,
  handleClose
}) => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const currentUser = useSelector((state) => state.userReducer.currentUser);

  const handleLogOut = () => {
    localStorage.clear();
    navigate('/sign-in');
    window.location.reload();
  };

  const openAccountInfo = () => {
    navigate('/account');
  };

  const openSupport = () => {
    window.location.href = 'https://unicoinvoicing.com/support';
  }

  return (
    <Menu
      className="account-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
    >
      <p className='account-menu-heading mb-0'>
        {currentUser?.user?.firstName +
          ' ' +
          currentUser?.user?.lastName}
      </p>
      <p className='account-menu-subtitle mb-2'>
        {currentUser?.user?.email}
      </p>
      {isMediumScreen &&
        <CustomButton
          label='Edit Business'
          type='medium'
          icon={
            <img
              src='/images/edit-icon.svg'
              alt='edit'
              style={{ width: '15px', height: '15px' }}
            />
          }
          onClick={() => navigate('/edit-business')}
        />
      }
      <Divider
        sx={{
          backgroundColor: '#d9d9d9',
          height: 1,
          marginTop: '13px',
          marginBottom: '13px'
        }}
      />
      <div className="d-flex align-items-center justify-content-between mb-2">
        <Chip
          label={
            currentUser?.user?.plan == 0 ? 'Free' :
              'Business'
          }
          sx={{
            width: '50%',
            backgroundColor: '#D8D4FB', // Change the background color
            color: '#473AB7', // Change the font color
            fontSize: '12px', // Change the font size
            fontWeight: '400',
            borderRadius: '10px', // Change the border radius
          }}
        />
        <p onClick={openAccountInfo} className="account-menu-link">
          Change plan?
        </p>
      </div>
      <MenuItem onClick={openAccountInfo}>
        <img width={18} src='/images/my-account-icon.svg' />
        <p>My Account</p>
      </MenuItem>
      <MenuItem onClick={openSupport}>
        <img width={18} src='/images/get-support-icon.svg' />
        <p>Get Support</p>
      </MenuItem>
      <MenuItem onClick={handleLogOut}>
        <img width={18} src='/images/log-out-icon.svg' />
        <p>Log Out</p>
      </MenuItem>
    </Menu>
  );
};

export default AccountMenu;