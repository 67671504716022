import React, { useEffect } from 'react';
import Sidebar from '../components/layout/Sidebar';
import BottomNavbar from '../components/layout/BottomNavbar';
import Header from '../components/layout/Header';
import AllModalsRoot from '../modals';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCustomers } from '../redux/actions/customer.actions';
import { getCurrentUser } from '../redux/actions/user.actions';

const MainLayout = ({ children }) => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.userReducer.currentUser);

  useEffect(() => {
    dispatch(getCurrentUser());
  }, []);

  //global calls
  useEffect(() => {
    if (currentUser?.business?.businessId)
      dispatch(getAllCustomers(currentUser.business.businessId));
  }, [currentUser]);

  return (
    <React.Fragment>
      <AllModalsRoot />
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-xl-2 d-none d-xl-inline p-0'>
            <div className='sidebar-root'>
              <Sidebar />
            </div>
          </div>
          <div className='col-xl-10 p-0' style={{ position: 'relative' }}>
            <Header />
            <div className='main-panel-root mh-100'>{children}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MainLayout;
