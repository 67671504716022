import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CustomButton from '../components/@generalComponents/CustomButton';
import { useNavigate } from 'react-router-dom';

const ModalNoCustomers = ({
  isOpen,
  modalCloseHandler,
}) => {
  const navigate = useNavigate();

  const navigateToCreateCustomer = () => {
    modalCloseHandler();
    navigate('/customers/create?redirectUrl=/invoices/create')
  }

  return (
    <Dialog open={isOpen} onClose={modalCloseHandler} maxWidth='xs'>
      <DialogTitle>
        <div className='d-flex justify-content-center'>
          <img height={133} src='/images/no-customers-graphic.svg' />
        </div>
        <h1 className='modal-title mt-3' style={{ lineHeight: 'normal' }}>
          Lets Get Started
        </h1>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          <p className='modal-desc'>
            Looks like you’ve got no customers yet!
            Create a customer first to send them an invoice
          </p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CustomButton
          label='Close'
          type='medium-grey'
          customStyle={{
            fontSize: '15px',
            fontWeight: '600',
          }}
          onClick={modalCloseHandler}
        />
        <CustomButton
          label='Create Customer'
          type='medium-purple'
          customStyle={{
            height: '38px',
            fontSize: '15px',
            fontWeight: '600',
          }}
          onClick={navigateToCreateCustomer}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ModalNoCustomers;
