import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/@generalComponents/Loader';
import Input from '../components/@generalComponents/Input';
import { Divider } from '@mui/material';
import CustomButton from '../components/@generalComponents/CustomButton';
import unicoAxios from '../utils/unicoAxios';
import { getCurrentUser } from '../redux/actions/user.actions';

const EditBusiness = () => {
  const dispatch = useDispatch();

  const [formState, setFormState] = useState({
    address: '',
    city: '',
    region: '',
    postcode: '',
    businessType: 0,
    name: '',
    number: '',
    isVATRegistered: false,
    vatNumber: null,
    paymentMethod: 0,
    paymentInformation: ''
  });

  const currentUser = useSelector((state) => state.userReducer.currentUser);

  useEffect(() => {
    if (!currentUser) return;
    setFormState({
      address: currentUser?.business?.address?.address,
      city: currentUser?.business?.address?.city,
      region: currentUser?.business?.address?.region,
      postcode: currentUser?.business?.address?.postcode,
      businessType: currentUser?.business?.businessType,
      name: currentUser?.business?.name,
      number: currentUser?.business?.number,
      isVATRegistered: currentUser?.business?.isVatRegistered,
      vatNumber: currentUser?.business?.vatNumber,
      logo: currentUser?.business?.logo,
      paymentMethod: currentUser?.business?.paymentMethod,
      paymentInformation: currentUser?.business?.paymentInformation,
    });
  }, [currentUser]);

  const blobToDataUrl = (blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });

  const handleFileSelect = async (e) => {
    const selectedFile = e.target.files[0];
    const base64 = await blobToDataUrl(selectedFile);
    setFormState({
      ...formState,
      logo: base64,
      logoName: selectedFile?.name,
    });
  };

  const changeHandler = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const saveChanges = async () => {
    await unicoAxios({
      method: 'PATCH',
      url: `businesses/${currentUser?.business?.businessId}`,
      body: {
        address: formState.address,
        city: formState.city,
        region: formState.region,
        postcode: formState.postcode,
        businessType: formState.businessType,
        name: formState.name,
        isVatRegistered: formState.isVATRegistered,

        ...(formState?.businessType == 0 && { number: formState.number }),
        ...(formState?.isVATRegistered && { vatNumber: formState.vatNumber }),
        ...(formState?.logoName && {
          logo: formState.logo,
          logoType: formState?.logoName?.split('.').pop(),
        }),
        ...(formState.paymentMethod && {
          paymentMethod: formState.paymentMethod,
          paymentInformation: formState.paymentInformation
        })
      },
      loadingMessage: 'Updating business details...',
      successMessage: 'Business details updated successfully!',
      onSuccess: () => dispatch(getCurrentUser()),
    });
  };

  return (
    <Loader renderChildren={currentUser}>
      <h1 className='heading-primary mb-0 mt-0'>Edit Business</h1>
      <div className='invoice-card'>
        <h4 className='heading-secondary mb-0'>Business Details</h4>
        <p className='subtitle-secondary mb-5'>
          This information will be used to format your invoices
        </p>
        <div className='row'>
          <div className='col-md-4 mb-4'>
            <Input
              type='select'
              value={formState.businessType}
              name='businessType'
              label='Business Type'
              options={[
                {
                  label: 'Limited Company',
                  value: 0,
                },
                {
                  label: 'Sole Trader',
                  value: 1,
                },
              ]}
              onChange={changeHandler}
            />
          </div>
          <div className='col-md-4 mb-4'>
            <Input
              type='text'
              label='Company Name'
              name='name'
              value={formState.name}
              onChange={changeHandler}
            />
          </div>
          <div className='col-md-4 mb-4'>
            <Input
              type='text'
              label='Company Number'
              name='number'
              disabled={formState?.businessType != 0}
              value={formState.number}
              onChange={changeHandler}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-4'>
            <div className='d-flex mb-4'>
              <Input
                type='checkbox'
                name='isVATRegistered'
                value={formState?.isVATRegistered}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    isVATRegistered: e.target.checked,
                  })
                }
              />
              <p
                className='prevent-select hover-on color-primary'
                onClick={() =>
                  setFormState({
                    ...formState,
                    isVATRegistered: !formState?.isVATRegistered,
                  })
                }
                style={{ margin: '0px 10px' }}
              >
                I am VAT Registered
              </p>
            </div>
            {formState?.isVATRegistered && (
              <div className='d-flex'>
                <Input
                  type='text'
                  label='VAT Number'
                  name='vatNumber'
                  value={formState.vatNumber}
                  onChange={changeHandler}
                />
              </div>
            )}
          </div>
        </div>
        <Divider
          sx={{
            backgroundColor: '#d9d9d9',
            height: 2,
            marginTop: '35px',
            marginBottom: '35px'
          }}
        />
        <div className='row'>
          <div className='edit-business-payment-method col-md-4 mb-4 mb-md-0'>
            <Input
              type='select'
              value={formState.paymentMethod}
              name='paymentMethod'
              label='Payment Method'
              options={[
                {
                  label: 'Paypal',
                  value: 0,
                },
                {
                  label: 'Stripe',
                  value: 1,
                },
                {
                  label: 'Bank Transfer',
                  value: 2,
                },
              ]}
              onChange={changeHandler}
            />
          </div>
          <div className='col-md-8'>
            <Input
              type='textarea'
              label='Payment Details'
              name='paymentInformation'
              value={formState.paymentInformation}
              onChange={changeHandler}
              rows={2}
            />
          </div>
        </div>
        <Divider
          sx={{
            backgroundColor: '#d9d9d9',
            height: 2,
            marginTop: '35px',
            marginBottom: '35px'
          }}
        />
        <p className='subtitle-secondary mb-4'>
          Business Address
        </p>
        <div className='row'>
          <div className='col-md-3 mb-4'>
            <Input
              type='text'
              name='address'
              value={formState.address}
              onChange={changeHandler}
              label='House No. & Street'
            />
          </div>
          <div className='col-md-3 mb-4'>
            <Input
              type='text'
              label='Town/City'
              name='city'
              value={formState.city}
              onChange={changeHandler}
            />
          </div>
          <div className='col-md-3 mb-4'>
            <Input
              type='text'
              label='Region'
              name='region'
              value={formState.region}
              onChange={changeHandler}
            />
          </div>
          <div className='col-md-3 mb-4'>
            <Input
              type='text'
              label='Postcode'
              name='postcode'
              value={formState.postcode}
              onChange={changeHandler}
            />
          </div>
        </div>
        <Divider
          sx={{
            backgroundColor: '#d9d9d9',
            height: 2,
            marginTop: '25px',
            marginBottom: '35px'
          }}
        />
        <img src={formState?.logo} width='15%' />
        <div className='row d-flex justify-content-between mt-3'>
          <div className='col-md-4 mb-4'>
            <Input
              type='file'
              label={
                formState?.logoName ? formState?.logoName : 'Upload Logo'
              }
              onChange={handleFileSelect}
            />
          </div>
          <div className='col-md-4 mb-4'>
            <CustomButton
              type='medium'
              label='Save'
              customStyle={{
                height: '100%',
                fontSize: '20px'
              }}
              onClick={saveChanges}
            />
          </div>
        </div>
      </div>
    </Loader >
  );
};

export default EditBusiness;
