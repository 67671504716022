import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ModalConfirmation from './ModalConfirmation';
import * as modalActions from '../redux/actions/modal.actions';
import ModalAddPayment from './ModalAddPayment';
import ModalNoCustomers from './ModalNoCustomers';
import ModalUpgradeSuccess from './ModalUpgradeSuccess';

const AllModalsRoot = () => {
  const dispatch = useDispatch();

  const globalModalState = useSelector((state) => state.modalReducer);

  return (
    <>
      {globalModalState.modalConfirmation.isOpen && (
        <ModalConfirmation
          {...globalModalState.modalConfirmation}
          modalCloseHandler={() =>
            dispatch(modalActions.modalConfirmation({ isOpen: false }))
          }
        />
      )}
      {globalModalState.modalAddPayment.isOpen && (
        <ModalAddPayment
          {...globalModalState.modalAddPayment}
          modalCloseHandler={() =>
            dispatch(modalActions.modalAddPayment({ isOpen: false }))
          }
        />
      )}
      {globalModalState.modalNoCustomers.isOpen && (
        <ModalNoCustomers
          {...globalModalState.modalNoCustomers}
          modalCloseHandler={() =>
            dispatch(modalActions.modalNoCustomers({ isOpen: false }))
          }
        />
      )}
      {globalModalState.modalUpgradeSuccess.isOpen && (
        <ModalUpgradeSuccess
          {...globalModalState.modalUpgradeSuccess}
          modalCloseHandler={() =>
            dispatch(modalActions.modalUpgradeSuccess({ isOpen: false }))
          }
        />
      )}
    </>
  );
};

export default AllModalsRoot;
