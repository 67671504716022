import React, { useEffect, useState } from 'react';
import Input from '../components/@generalComponents/Input';
import CustomButton from '../components/@generalComponents/CustomButton';
import { useNavigate } from 'react-router-dom';
import { Divider } from '@mui/material';
import Loader from '../components/@generalComponents/Loader';
import { useDispatch, useSelector } from 'react-redux';
import unicoAxios from '../utils/unicoAxios';
import { getCurrentUser } from '../redux/actions/user.actions';

const CreateBusiness = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formState, setFormState] = useState({
    address: '',
    city: '',
    region: '',
    postcode: '',
    businessType: 1,
    name: '',
    number: '',
    isVATRegistered: false,
    vatNumber: null,
    paymentMethod: null,
    paymentInformation: '',
  });

  const currentUser = useSelector((state) => state.userReducer.currentUser);

  useEffect(() => {
    if (currentUser) return;
    dispatch(getCurrentUser());
  }, [currentUser])

  const changeHandler = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const openPaymentLink = () => {
    if (formState.paymentMethod == 0)
      window.open('https://www.paypal.com/uk/cshelp/article/what-is-paypalme-help432', '__blank')
    else
      window.open('https://docs.stripe.com/payment-links/create?locale=en-GB', '__blank')
  };

  const blobToDataUrl = (blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });

  const handleFileSelect = async (e) => {
    const selectedFile = e.target.files[0];
    const base64 = await blobToDataUrl(selectedFile);
    setFormState({
      ...formState,
      logo: base64,
      logoName: selectedFile?.name,
    });
  };

  const onBusinessCreationSuccess = () => {
    dispatch(getCurrentUser(() => navigate('/dashboard')));
  };

  const handleCreateBusiness = async () => {
    await unicoAxios({
      method: 'POST',
      url: 'businesses/create',
      body: {
        address: formState.address,
        city: formState.city,
        region: formState.region,
        postcode: formState.postcode,
        businessType: formState.businessType,
        name: formState.name,
        ...(formState?.businessType == 0 && { number: formState.number }),
        isVatRegistered: formState.isVATRegistered,
        ...(formState?.isVATRegistered && { vatNumber: formState.vatNumber }),
        logo: formState.logo,
        logoType: formState?.logoName?.split('.').pop(),
        ...(formState.paymentMethod && {
          paymentMethod: formState.paymentMethod,
          paymentInformation: formState.paymentInformation
        })
      },
      loadingMessage: 'Creating business...',
      successMessage: 'Business created successfully!',
      onSuccess: onBusinessCreationSuccess,
    });
  };

  return (
    <Loader renderChildren={currentUser}>
      <div className='container-fluid'>
        <div className='row login-holder'>
          <div className='col-lg-5 d-flex align-items-center justify-content-center login-form'>
            <div
              className='row ps-lg-5 pe-lg-5'
              style={{
                height: '100vh',
                overflowY: 'auto',
              }}
            >
              <div className='d-none d-lg-flex mt-3'>
                <img
                  className='create-business-form-logo'
                  src='/images/unico-logo-large.svg'
                />
              </div>
              <div className='d-flex justify-content-center d-lg-none'>
                <img
                  className='mt-5'
                  src='/images/create-business-graphic-2.svg'
                />
              </div>
              <h1 className='mt-4 mb-5'>
                Let's get you started {currentUser?.user?.firstName}...
              </h1>
              <p className='color-primary fw-500 mb-4'>
                Address (to display on invoices)
              </p>
              <div className='col-sm-6 mb-4'>
                <Input
                  type='text'
                  name='address'
                  value={formState.address}
                  onChange={changeHandler}
                  label='House No. & Street'
                />
              </div>
              <div className='col-sm-6 mb-4'>
                <Input
                  type='text'
                  label='Town/City'
                  name='city'
                  value={formState.city}
                  onChange={changeHandler}
                />
              </div>
              <div className='col-6'>
                <Input
                  type='text'
                  label='Region'
                  name='region'
                  value={formState.region}
                  onChange={changeHandler}
                />
              </div>
              <div className='col-6 mb-4'>
                <Input
                  type='text'
                  label='Postcode'
                  name='postcode'
                  value={formState.postcode}
                  onChange={changeHandler}
                />
              </div>
              <Divider
                sx={{
                  backgroundColor: '#D9D9D9',
                  height: 2,
                  marginTop: 1,
                  marginBottom: 1,
                }}
              />
              <p className='color-primary fw-500 mb-4 mt-3'>
                Business Details (to format your invoices)
              </p>
              <div className='col-12 mb-4'>
                <Input
                  type='select'
                  value={formState.businessType}
                  name='businessType'
                  label='Business Type'
                  options={[
                    {
                      label: 'Limited Company',
                      value: 0,
                    },
                    {
                      label: 'Sole Trader',
                      value: 1,
                    },
                  ]}
                  onChange={changeHandler}
                />
              </div>
              {formState.businessType === 0 ? (
                <React.Fragment>
                  <div className='col-6'>
                    <Input
                      type='text'
                      label='Company Name'
                      name='name'
                      value={formState.name}
                      onChange={changeHandler}
                    />
                  </div>
                  <div className='col-6'>
                    <Input
                      type='text'
                      label='Company Number'
                      name='number'
                      value={formState.number}
                      onChange={changeHandler}
                    />
                  </div>
                </React.Fragment>
              ) : (
                <div className='col-12'>
                  <Input
                    type='text'
                    label='Business Name'
                    name='name'
                    value={formState.name}
                    onChange={changeHandler}
                  />
                </div>
              )}
              <div className='col-12 d-flex'>
                <Input
                  type='checkbox'
                  name='isVATRegistered'
                  value={formState?.isVATRegistered}
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      isVATRegistered: e.target.checked,
                    })
                  }
                />
                <p
                  className='prevent-select hover-on color-primary mt-4'
                  onClick={() =>
                    setFormState({
                      ...formState,
                      isVATRegistered: !formState?.isVATRegistered,
                    })
                  }
                >
                  I am VAT Registered
                </p>
              </div>
              {formState?.isVATRegistered && (
                <div className='col-12 d-flex mb-4'>
                  <Input
                    type='text'
                    label='VAT Number'
                    name='vatNumber'
                    value={formState.vatNumber}
                    onChange={changeHandler}
                  />
                </div>
              )}
              <Divider
                sx={{
                  backgroundColor: '#D9D9D9',
                  height: 2,
                  marginTop: 1,
                  marginBottom: 1,
                }}
              />
              <p className='color-primary fw-500 mb-4 mt-3'>
                How would you like to get paid?
              </p>
              <div className='col-12 mb-4'>
                <Input
                  type='select'
                  value={formState.paymentMethod}
                  name='paymentMethod'
                  label='Payment Method'
                  placeholder='Select a payment method'
                  options={[
                    {
                      label: 'Paypal',
                      value: 0,
                    },
                    {
                      label: 'Stripe',
                      value: 1,
                    },
                    {
                      label: 'Bank Transfer',
                      value: 2,
                    },
                  ]}
                  onChange={changeHandler}
                />
              </div>
              {formState.paymentMethod != null && (
                <div className='col-12 mb-2'>
                  <Input
                    type={formState.paymentMethod == 2 ? 'textarea' : 'text'}
                    label='Details'
                    name='paymentInformation'
                    value={formState.paymentInformation}
                    onChange={changeHandler}
                    rows={4}
                    maxRows={8}
                  />
                  {formState.paymentMethod != 2 && (
                    <div className='d-flex justify-content-end'>
                      <p
                        className='hover-on text-link mb-0'
                        onClick={openPaymentLink}
                      >
                        How to generate a{' '}
                        {formState.paymentMethod == 0 ? 'PayPal ' : 'Stripe '}
                        link?
                      </p>
                    </div>
                  )}
                </div>
              )}
              <Divider
                sx={{
                  backgroundColor: '#D9D9D9',
                  height: 2,
                  marginTop: 3,
                  marginBottom: 1,
                }}
              />
              <div className='col-12 mt-4 mb-3'>
                <Input
                  type='file'
                  label={
                    formState?.logoName ? formState?.logoName : 'Upload Logo'
                  }
                  onChange={handleFileSelect}
                />
              </div>
              <div className='col-12 mt-4 mb-4'>
                <CustomButton
                  type='large'
                  label='Start Invoicing'
                  onClick={handleCreateBusiness}
                />
              </div>
            </div>
          </div>
          <div className='col-lg-7 d-none d-lg-flex login-graphic justify-content-center align-items-center'>
            <img
              className='login-form-graphic-2'
              src='/images/create-business-graphic.svg'
            />
          </div>
        </div>
      </div>
    </Loader>
  );
};

export default CreateBusiness;
