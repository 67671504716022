import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CustomButton from '../components/@generalComponents/CustomButton';
import { useSearchParams } from 'react-router-dom';

const ModalUpgradeSuccess = ({
  isOpen,
  modalCloseHandler,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onContinue = () => {
    setSearchParams({});
    modalCloseHandler();
  }

  return (
    <Dialog
      open={isOpen}
      onClose={modalCloseHandler}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "470px",  // Set your width here
          },
        },
      }}
    >
      <div className='d-flex justify-content-center'>
        <img src='/images/upgrade-success-graphic.svg' width={200} height={200} />
      </div>
      <h1
        className='text-center'
        style={{
          fontSize: '32px',
          fontWeight: 700,
          color: '#081159'
        }}
      >
        Upgrade Complete
      </h1>
      <p
        className='text-center'
        style={{
          fontSize: '14px',
          fontWeight: 500,
          color: '#081159'
        }}
      >
        Thank you for joining the team!
      </p>
      <DialogActions sx={{ padding: 0, marginTop: '30px' }}>
        <CustomButton
          label='Continue'
          type='medium-purple'
          customStyle={{
            fontSize: '15px',
            fontWeight: '600',
            height: '38px'
          }}
          onClick={onContinue}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ModalUpgradeSuccess;
