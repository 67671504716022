import React from 'react';
import Input from '../@generalComponents/Input';
import CurrencyInput from '../@generalComponents/CurrencyInput';
import { Divider, IconButton, Tooltip } from '@mui/material';

const InvoiceTotals = ({
  invoiceDetails,
  setInvoiceDetails,
  includeVat,
  setIncludeVat,
  businessVatRegistered,
  canApplyDiscount,
  calculateTotals,
}) => {
  return (
    <div className='row'>
      <div className='col-md-7'>
        <p
          style={{
            fontSize: '12px',
            fontWeight: 500,
            marginBottom: '5px'
          }}
        >
          Notes (Displayed on invoice)
        </p>
        <Input
          type='textarea'
          name='notes'
          onChange={(e) =>
            setInvoiceDetails({
              ...invoiceDetails,
              notes: e.target.value,
            })
          }
          value={invoiceDetails?.notes}
          rows={3}
        />
        {businessVatRegistered && (
          <div className='d-flex align-items-center'>
            <Input
              type='checkbox'
              value={includeVat}
              onChange={(e) => setIncludeVat(e.target.checked)}
            />
            <p
              className='prevent-select hover-on invoice-vat-inclusive mt-4 mb-4 ms-2'
              onClick={() => setIncludeVat(!includeVat)}
            >
              Enter prices <b>inclusive</b> of VAT
            </p>
          </div>
        )}
      </div>
      <div className='col-md-5'>
        <div className='row'>
          <div className='col-6 d-flex justify-content-start invoice-summary-text'>
            Subtotal
          </div>
          <div className='col-6 d-flex justify-content-end invoice-summary-text'>
            £{Number(invoiceDetails?.subtotal).toFixed(2)}
          </div>
          <div className='col-5 d-flex justify-content-start invoice-summary-text align-items-center'>
            Discount
          </div>
          <div className='col-7 d-flex justify-content-end invoice-summary-text'>
            {invoiceDetails?.discountType && canApplyDiscount() ? (
              <CurrencyInput
                currencyValue={invoiceDetails?.discountType}
                onCurrencyChange={(e) =>
                  setInvoiceDetails({
                    ...invoiceDetails,
                    discountType: e.target.value,
                  })
                }
                inputValue={invoiceDetails?.discountValue}
                onInputChange={(e) =>
                  setInvoiceDetails({
                    ...invoiceDetails,
                    discountValue: e.target.value,
                  })
                }
                onInputBlur={calculateTotals}
              />
            ) : !canApplyDiscount() ? (
              <Tooltip title='Discount cannot be applied for items with mixed VAT rates'>
                <p className='mb-0 hover-on'>-</p>
              </Tooltip>
            ) : (
              <div>
                <IconButton
                  onClick={() =>
                    setInvoiceDetails({
                      ...invoiceDetails,
                      discountType: 'UNIT',
                      discountValue: 0.0,
                    })
                  }
                  sx={{ borderRadius: '5px' }}
                >
                  <p className='invoice-add-item mb-0'>Add +</p>
                </IconButton>
              </div>
            )}
          </div>
          <div className='col-6 d-flex justify-content-start invoice-summary-text'>
            {businessVatRegistered ? 'VAT' : <>&nbsp;</>}
          </div>
          <div className='col-6 d-flex justify-content-end invoice-summary-text'>
            {businessVatRegistered ? (
              `£${Number(invoiceDetails?.vat).toFixed(2)}`
            ) : (
              <>&nbsp;</>
            )}
          </div>
          <Divider
            sx={{
              backgroundColor: '#d9d9d9',
              height: 2,
              marginTop: '8px',
              marginBottom: '20px',
            }}
          />
          <div className='col-6 d-flex justify-content-start invoice-summary-text'>
            Total
          </div>
          <div className='col-6 d-flex justify-content-end invoice-summary-text'>
            £{Number(invoiceDetails?.total).toFixed(2)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceTotals;
