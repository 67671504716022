import React, { useState } from 'react';
import Input from '../@generalComponents/Input';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { invoiceTerms } from '../../exports/commonExports';
import moment from 'moment';

const CustomerDetails = ({
  selectedCustomer,
  invoiceDetails,
  setInvoiceDetails,
}) => {
  const { invoiceId } = useParams();
  const navigate = useNavigate();

  const [terms, setTerms] = useState(invoiceDetails?.terms || 30);

  const onTermsChange = (value) => {
    setTerms(value);
    if (value === 'CUSTOM') return;
    setInvoiceDetails({
      ...invoiceDetails,
      dueDate: moment(invoiceDetails?.invoiceDate).add(value, 'days').toDate(),
    })
  };

  if (selectedCustomer)
    return (
      <div className='mt-4 mb-4 invoice-details-root'>
        <div className='invoice-details-header'>
          <div className='d-flex justify-content-between'>
            <h1>
              {selectedCustomer?.firstName +
                ' ' +
                selectedCustomer?.lastName +
                ' | ' +
                selectedCustomer?.businessName}
            </h1>
            <IconButton
              size='medium'
              sx={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '4px',
              }}
              onClick={() => navigate(`/customers/${selectedCustomer.customerId}`)}
            >
              <img
                src='/images/edit-icon.svg'
                width={20}
                height={20}
              />
            </IconButton>
          </div>
          <p>{selectedCustomer?.email}</p>
        </div>
        <div className='row invoice-details-footer mb-2'>
          <div className='col-md-12'>
            {invoiceId && (
              <p
                style={{
                  fontSize: '22px',
                  fontWeight: '700',
                  marginBottom: '0px',
                }}
              >
                #{invoiceDetails?.invoiceNumber}
              </p>
            )}
          </div>
          <div className='col-md-4'>
            <p className='mb-0'>Date:</p>
            <Input
              type='date'
              thin
              value={invoiceDetails?.invoiceDate}
              onChange={(e) => {
                setInvoiceDetails({
                  ...invoiceDetails,
                  invoiceDate: e?.$d,
                });
              }}
            />
          </div>
          <div className='col-md-4'>
            <p style={{ marginBottom: 7 }}>Terms:</p>
            <Input
              customThickness={'8.9px'}
              type='select'
              value={terms}
              placeholder='Select a term'
              options={invoiceTerms}
              sx={{ padding: 1 }}
              onChange={(e) => onTermsChange(e?.target?.value)}
            />
          </div>
          <div className='col-md-4'>
            <p className='mb-0'>Due:</p>
            <Input
              type='date'
              thin
              value={invoiceDetails?.dueDate}
              onChange={(e) => {
                setTerms('CUSTOM');
                setInvoiceDetails({
                  ...invoiceDetails,
                  dueDate: e?.$d,
                });
              }}
            />
          </div>
          <div className='col-md-12 mt-3'>
            <p className='billed-to-title mb-1'>Billed to:</p>
            <p className='billed-to-text'>
              {selectedCustomer?.address?.address},{' '}
            </p>
            <p className='billed-to-text'>
              {selectedCustomer?.address?.city},{' '}
            </p>
            <p className='billed-to-text'>
              {selectedCustomer?.address?.region},{' '}
            </p>
            <p className='billed-to-text'>
              {selectedCustomer?.address?.country},{' '}
              {selectedCustomer?.address?.postcode}
            </p>
          </div>
        </div>
      </div>
    );
  else return null;
};
export default CustomerDetails;
