import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import {
  Checkbox,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Input as MuiInput,
  Button,
  InputLabel,
} from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const CssTextField = styled(TextField, {
  shouldForwardProp: (props) =>
    props !== 'focusColor' &&
    props !== 'startIcon' &&
    props !== 'thin' &&
    props !== 'type' &&
    props !== 'inputAdornment',
})((p) => ({
  width: '100%',
  '& .MuiInputLabel-root': {
    fontWeight: 500,
    fontFamily: 'Poppins',
  },
  // input label when focused
  '& label.Mui-focused': {
    color: p.focusColor,
  },
  // focused color for input with variant='standard'
  '& .MuiInput-underline:after': {
    borderBottomColor: p.focusColor,
  },
  // focused color for input with variant='filled'
  '& .MuiFilledInput-underline:after': {
    borderBottomColor: p.focusColor,
  },
  // focused color for input with variant='outlined'
  '& .MuiOutlinedInput-root': {
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    '&.Mui-focused fieldset': {
      border: '1px solid #473AB7',
    },
  },
  '& .MuiOutlinedInput-input': {
    fontSize: '14px',
    fontWeight: '500',
    fontFamily: 'Poppins',
    padding: '6px 5px 7px 10px !important',
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    borderLeft: 'none !important',
    borderRadius: '0px 4px 4px 0px !important',
  },
}));

const CssTextFieldSelect = styled(TextField, {
  shouldForwardProp: (props) =>
    props !== 'focusColor' &&
    props !== 'startIcon' &&
    props !== 'thin' &&
    props !== 'type' &&
    props !== 'inputAdornment',
})((p) => ({
  width: '60%',
  '& .MuiInputLabel-root': {
    fontWeight: 500,
    fontFamily: 'Poppins',
  },
  // input label when focused
  '& label.Mui-focused': {
    color: p.focusColor,
  },
  // focused color for input with variant='standard'
  '& .MuiInput-underline:after': {
    borderBottomColor: p.focusColor,
  },
  // focused color for input with variant='filled'
  '& .MuiFilledInput-underline:after': {
    borderBottomColor: p.focusColor,
  },
  // focused color for input with variant='outlined'
  '& .MuiOutlinedInput-root': {
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    '&.Mui-focused fieldset': {
      border: '1px solid #473AB7',
    },
  },
  '& .MuiOutlinedInput-input': {
    fontSize: '14px',
    fontWeight: '500',
    fontFamily: 'Poppins',
    padding: '5px 5px 5px 10px !important',
    borderRight: 'none',
    backgroundColor: '#473AB7',
    color: 'white',
    borderRight: 'none !important',
    borderRadius: '4px 0px 0px 4px !important',
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    borderRight: 'none !important',
    borderRadius: '4px 0px 0px 4px !important',
  },
  '& .MuiSelect-root': {
    backgroundColor: 'purple !important',
  },
  '& .MuiSvgIcon-root': {
    right: '-2px !important',
    color: 'white',
  },
}));

const CurrencyInput = ({
  currencyValue,
  onCurrencyChange,
  inputValue,
  onInputChange,
  onInputBlur,
}) => {
  return (
    <React.Fragment>
      <CssTextFieldSelect
        focusColor='#473AB7'
        id='outlined-basic'
        type='select'
        select
        variant='outlined'
        onChange={onCurrencyChange}
        onBlur={onInputBlur}
        value={currencyValue}
      >
        <MenuItem value='UNIT'>£</MenuItem>
        <MenuItem value='PERCENTAGE'>%</MenuItem>
      </CssTextFieldSelect>
      <CssTextField
        focusColor='#473AB7'
        id='outlined-basic'
        placeholder='0.00'
        variant='outlined'
        onChange={onInputChange}
        value={inputValue}
        onBlur={onInputBlur}
      />
    </React.Fragment>
  );
};

export default CurrencyInput;
